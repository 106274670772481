// components/custom-editor.js


import React, { useEffect } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import './styles.scss'

const editorConfiguration = {
    toolbar: [
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'subscript',
        'superscript',
        'heading',
        // 'style',
        // 'textPartLanguage',
        'fontFamily',
        'fontSize',
        '|',
        'fontColor',
        'fontBackgroundColor',
        'showBlocks',
        'highlight',
        '|',
        'bulletedList',
        'numberedList',
        'todoList',
        'outdent',
        'indent',
        'alignment',
        '|',
        'link',
        'code',
        'codeBlock',
        // 'imageUpload',
        'imageInsert',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'findAndReplace',
        'htmlEmbed',
        'pageBreak',
        'horizontalLine',
        'specialCharacters',
        'restrictedEditingException',
        'selectAll',
        'removeFormat',
        'sourceEditing'
    ],
    removePlugins: ['Title'],
};

function CustomEditor({data, onChange}) {

    useEffect(() => {
        setTimeout(() => {
            const css = document.querySelector('span[data-ck-unsafe-element]')?.innerHTML;
            if(css) {
                const head = document.head || document.getElementsByTagName('head')[0];
                const style = document.querySelector('style#ck-editor-style') || document.createElement('style');
    
                head.appendChild(style);
    
                style.type = 'text/css';
                style.id = 'ck-editor-style'
                if (style.styleSheet){
                    // This is required for IE8 and below.
                    style.styleSheet.cssText = css;
                } else {
                    style.innerHTML = (css);
                }
            }
        }, 100)   
    }, [data])

    return (
        <CKEditor
            editor={ Editor }
            config={ editorConfiguration }
            data={ data }
            onChange={ (event, editor ) => {
                onChange(event, editor)
            } }
        />
    )
}

export default CustomEditor;