
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import { getCurrentUser } from '../../utils/utils';

import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Loader from '../../components/atoms/Loader';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Modal from '../../components/atoms/Modal';
import ToggleSwitch from '../../components/atoms/ToggleSwitch';

import './styles.scss';

const Smtp = () => {
    const emptyData = {

        clientSmtpServer: '',
        clientSmtpPort: '',
        clientSmtpUser: '',
        clientSmtpPassword: '',
        clientSmtpSenderName: '',
        clientSmtpSenderEmail: '',
        clientSmtpToName: '',
        clientSmtpToEmail: '',
        isActive: true
    };
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState('');
    const [editing, setEditing] = useState(false);
    const [data, setData] = useState(emptyData);
    const [userData, setUserData] = useState({});

    const [errors, setErrors] = useState({
        clientSmtpServer: '',
        clientSmtpPort: '',
        clientSmtpUser: '',
        clientSmtpPassword: '',
        clientSmtpSenderName: '',
        clientSmtpSenderEmail: '',
        clientSmtpToName: '',
        clientSmtpToEmail: '',
        isActive: false
    });
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(emptyData);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin');
        }

        if (user) {
            setUserData({
                username: user?.clientSmtpServer,
                clientSmtpId: user?.clientSmtpId,
            });
        }
        getData();
    }, []);


    const getData = async () => {
        try {
            setLoading(true);
            const response = await getRequest('ClientSMTP/GetSMTPDetail');
            if (response.status === 200) {
                setData(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching SMTP details:', error);
            setLoading(false);
        }
    }

    const handleValidation = () => {
        const errorObj = {};
        if (data.clientSmtpServer === '') {
            errorObj.clientSmtpServer = 'Please enter SMTP Server';
        } else {
            errorObj.clientSmtpServer = '';
        }
        if (data.clientSmtpPort === '') {
            errorObj.clientSmtpPort = 'Please enter Port';
        } else {
            errorObj.clientSmtpPort = '';
        }
        if (data.clientSmtpUser === '') {
            errorObj.clientSmtpUser = 'Please enter User';
        } else {
            errorObj.clientSmtpUser = '';
        }
        if (data.clientSmtpPassword === '') {
            errorObj.clientSmtpPassword = 'Please enter Password';
        } else {
            errorObj.clientSmtpPassword = '';
        }
        if (data.clientSmtpSenderName === '') {
            errorObj.clientSmtpSenderName = 'Please enter Sender Name';
        } else {
            errorObj.clientSmtpSenderName = '';
        }
        if (data.clientSmtpSenderEmail === '') {
            errorObj.clientSmtpSenderEmail = 'Please enter Sender Email';
        } else {
            errorObj.clientSmtpSenderEmail = '';
        }
        if (data.clientSmtpToName === '') {
            errorObj.clientSmtpToName = 'Please enter Receiver Name';
        } else {
            errorObj.clientSmtpToName = '';
        }
        if (data.clientSmtpToEmail === '') {
            errorObj.clientSmtpToEmail = 'Please enter Receiver Name';
        } else {
            errorObj.clientSmtpToEmail = '';
        }
        if (data.isActive === '') {
            errorObj.isActive = 'Please enter Display Order';
        } else {
            errorObj.isActive = '';
        }
        setErrors({ ...errors, ...errorObj })

        const validated = data.clientSmtpServer && data.clientSmtpPort && data.clientSmtpUser && data.clientSmtpPassword && data.clientSmtpSenderName && data.clientSmtpSenderEmail && data.clientSmtpToEmail && data.clientSmtpToName;
        return validated;
    }


    const handleChange = (value, type) => {
        setData({
            ...data,
            [type]: value
        })
    }

    const handleTableCheckboxChange = (e, rowdata) => {
        e.preventDefault();
        setConfirmModalOpen(true);
        setConfirmModalData(rowdata)
    }

    const handleSubmit = () => {
        const validated = handleValidation();

        if (validated) {
            handleSave();
        }
    }

    const handleSave = async (existingData = {}) => {
        setLoading(true);
        let postObj = {

            clientSmtpServer: data.clientSmtpServer,
            clientSmtpPort: data.clientSmtpPort,
            clientSmtpUser: data.clientSmtpUser,
            clientSmtpPassword: data.clientSmtpPassword,
            clientSmtpSenderName: data.clientSmtpSenderName,
            clientSmtpSenderEmail: data.clientSmtpSenderEmail,
            clientSmtpToName: data.clientSmtpToName,
            clientSmtpToEmail: data.clientSmtpToEmail,
            isActive: true,
            clientSmtpId: data.clientSmtpId

        }

        if (data.clientSmtpId) {
            postObj.clientSmtpId = data.clientSmtpId
        }

        if (existingData.clientSmtpId) {
            postObj = {
                clientSmtpServer: existingData.clientSmtpServer,
                clientSmtpPort: existingData.clientSmtpPort,
                clientSmtpUser: existingData.clientSmtpUser,
                clientSmtpPassword: existingData.clientSmtpPassword,
                clientSmtpSenderName: existingData.clientSmtpSenderName,
                clientSmtpSenderEmail: existingData.clientSmtpSenderEmail,
                clientSmtpToName: existingData.clientSmtpToName,
                clientSmtpToEmail: existingData.clientSmtpToEmail,
                isActive: true,
                clientSmtpId: existingData.clientSmtpId

            }
        }

        const response = await postRequest(`ClientSMTP/PostClientSMTP`, postObj);
        if (response.status === 200) {
            setEditing(false);
            setData(emptyData);
            getData();
        }
        else {
            console.error('Error: Unable to save data.');
        }
        setLoading(false);
    }



    const handleCloseCofirmationModal = () => {
        setConfirmModalData(emptyData);
        setConfirmModalOpen(false);
    }

    const handleConfirmModalYes = () => {
        confirmModalData.isActive = !confirmModalData.isActive;
        handleSave(confirmModalData);
        setConfirmModalOpen(false);
    }

    return (
        <MainA active="whats-new" className="b-smtp-wrapper">
            <Loader loading={loading} />
            <Modal isOpen={confirmModalOpen} closeModal={handleCloseCofirmationModal} >
                <h4>Are you sure you want to change the status?</h4>
                <div className='v-modal-button-wrapper'>
                    <Button onClick={handleConfirmModalYes} >Yes, Change it</Button>
                    <Button onClick={handleCloseCofirmationModal}type="primary" >Cancel</Button>
                </div>

            </Modal>
            {userType === 'admin' ?
                <div className='wrapper'>
                    <h1>SMTP</h1>
                    <div className='details'>
                        <div className='duo'>
                            <div className='row'>
                                <label>Client Server</label>
                                <Input type="text" value={data.clientSmtpServer} onChange={(e) => handleChange(e.target.value, 'clientSmtpServer')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpServer && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpServer}</ErrorMsg>
                                )}
                            </div>
                            <div className='row'>
                                <label>Client Port</label>
                                <Input type="text" value={data.clientSmtpPort} onChange={(e) => handleChange(e.target.value, 'clientSmtpPort')} placeholder="Enter Page Title here..." />
                                {errors.clientSmtpPort && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpPort}</ErrorMsg>
                                )}
                            </div>
                        </div>
                        <div className='duo'>
                            <div className='row'>
                                <label>Client User</label>
                                <Input type="text" value={data.clientSmtpUser} onChange={(e) => handleChange(e.target.value, 'clientSmtpUser')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpUser && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpUser}</ErrorMsg>
                                )}
                            </div>
                            <div className='row'>
                                <label>Client Password</label>
                                <Input type="text" value={data.clientSmtpPassword} onChange={(e) => handleChange(e.target.value, 'clientSmtpPassword')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpPassword && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpPassword}</ErrorMsg>
                                )}
                            </div>
                        </div>
                        <div className='duo'>
                            <div className='row'>
                                <label>Client Sender Name</label>
                                <Input type="text" value={data.clientSmtpSenderName} onChange={(e) => handleChange(e.target.value, 'clientSmtpSenderName')} placeholder="Enter Redirect URL here..." />
                                {errors.clientSmtpSenderName && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpSenderName}</ErrorMsg>
                                )}

                            </div>
                            <div className='row'>
                                <label>Client Sender Email</label>
                                <Input type="text" value={data.clientSmtpSenderEmail} onChange={(e) => handleChange(e.target.value, 'clientSmtpSenderEmail')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpSenderEmail && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpSenderEmail}</ErrorMsg>
                                )}
                            </div>
                        </div>
                        <div className='duo'>
                            <div className='row'>
                                <label>Client Receiver Name</label>
                                <Input type="text" value={data.clientSmtpToName} onChange={(e) => handleChange(e.target.value, 'clientSmtpToName')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpToName && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpToName}</ErrorMsg>
                                )}
                            </div>
                            <div className='row'>
                                <label>Client Receiver Email</label>
                                <Input type="text" value={data.clientSmtpToEmail} onChange={(e) => handleChange(e.target.value, 'clientSmtpToEmail')} placeholder="Enter Page Name here..." />
                                {errors.clientSmtpToEmail && (
                                    <ErrorMsg className="v-errorMsg">{errors.clientSmtpToEmail}</ErrorMsg>
                                )}
                            </div>
                        </div>
                        <div className='active'>
                            <label>Active</label>
                            <ToggleSwitch
                                checked={data.isActive}
                                onChange={(e) => handleTableCheckboxChange(e)}
                            />
                        </div>
                        <div>
                            <Button onClick={handleSubmit}>Save</Button>
                        </div>
                    </div>


                </div>
                : 'Page Not Found'

            }
        </MainA>
    );
}

export default Smtp;