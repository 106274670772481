import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AdminRouter from './admin/AdminRoutes';

const router = createBrowserRouter([
  ...AdminRouter,
]);
const Routes = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default Routes;