import React from 'react';
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import './styles.scss';

const Anchor = ({className, to, type, disabled, onClick, children, target, ...rest}) => {
  return (
    <Link
        to={to}
        className={`v-link ${className} ${type}`}
        disabled={disabled}
        onClick={onClick}
        target={target}
       
        {...rest}
    >
      {children}
    </Link>
  );
}

Anchor.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    target: PropTypes.string,
}

Anchor.defaultProps = {
    className: '',
    type: '',
    disabled: false,
    onClick: () => {},
    children: <></>,
    target: '',

}

export default Anchor;