import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../../utils/axios';

import MainA from '../../layout/Main Admin';
import Loader from "../../components/atoms/Loader";
import Modal from "../../components/atoms/Modal";
import Button from "../../components/atoms/Button";

import backArrow from '../../assets/icons/arrow-left.svg';

import './styles.scss';

const ClientDeletedImages = () => {
    const { galleryClientId } = useParams();
    const [loading, setLoading] = useState(true);
    const [deletedImages, setDeletedImages] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDeletedImages = async () => {
            try {
                const response = await getRequest(`Azure/GetDeletedGalleryImages/${galleryClientId}`);
                setDeletedImages(response.data);
            } catch (error) {
                console.error("Error fetching deleted images", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDeletedImages();
    }, [galleryClientId]);

    const extractBlobFilename = (uri) => {
        const parts = uri.split('/gallery/');
        return parts[1];
    };

    const handleDeleteClick = (image) => {
        setImageToDelete(image);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        setLoading(true);
        if (!imageToDelete) return;

        const blobFilename = extractBlobFilename(imageToDelete.uri);

        try {
            await postRequest(`Azure/DeletedGalleryImage`, { imageNameWithPath: blobFilename });
            setDeletedImages((prevImages) => prevImages.filter(img => img.name !== imageToDelete.name));
            setShowDeleteModal(false);
        } catch (error) {
            console.error("Error deleting image", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainA className="b-delete-wrapper">
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <div className="wrapper">
                    <div className="head">
                        <h2>Deleted Images</h2>
                        <Button onClick={() => navigate(-1)} >
                            <img src={backArrow} alt='back arrow' /> Back
                        </Button>
                    </div>
                    <div className="images-list">
                        {deletedImages.length > 0 ? (
                            deletedImages.map((image, index) => (
                                <div className="data" key={index}>
                                    <div className="image">
                                        <img src={image.uri} alt={image.name} />
                                        <p>{image.name}</p>
                                    </div>
                                    <Button className="delete-btn" onClick={() => handleDeleteClick(image)}>
                                        Delete
                                    </Button>
                                </div>
                            ))
                        ) : (
                            <p>No deleted images found.</p>
                        )}
                    </div>

                    <Modal isOpen={showDeleteModal} closeModal={() => setShowDeleteModal(false)} className="delete-modal">

                        <div className="circle">!</div>
                        <h4>Are you sure you want to delete this image?</h4>
                        <p>{imageToDelete?.name}</p>
                        <div className="modal-buttons">
                            <Button onClick={handleDeleteConfirm}>Yes, Delete</Button>
                            <Button type="primary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                        </div>

                    </Modal>
                </div>
            )}
        </MainA>
    );
};

export default ClientDeletedImages;
