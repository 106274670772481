import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRequest } from '../../utils/axios';

import MainA from '../../layout/Main Admin';
import Loader from '../../components/atoms/Loader';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal'; // Import the Modal component

import './styles.scss';

const AddIn = () => {
    const [addIns, setAddIns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        setLoading(true);
        try {
            const resp = await getRequest('Module/GetClientModules');
            setAddIns(resp.data);
        } catch (error) {
            console.error('Failed to fetch modules:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (moduleId, isActive) => {
        setLoading(true);
        try {
            await getRequest(`Module/ChangeStatus/${moduleId}/${isActive}`);
            getCategories();
        } catch (error) {
            console.error('Failed to update status:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubscriptionChange = (module) => {
        setSelectedModule(module);
        setIsConfirmModalOpen(true);
    };

    const handleConfirmSubscriptionChange = async () => {
        if (selectedModule) {
            await handleSave(selectedModule.moduleId, !selectedModule.clientModule[0]?.isActive);
            setIsConfirmModalOpen(false);
        }
    };

    const filteredAddIns = () => {
        switch (activeTab) {
            case 'paid':
                return addIns.filter((module) => module.isPaid);
            case 'free':
                return addIns.filter((module) => !module.isPaid);
            case 'active':
                return addIns.filter((module) => module.clientModule[0]?.isActive);
            case 'inactive':
                return addIns.filter((module) => !module.clientModule[0]?.isActive);
            case 'all':
            default:
                return addIns;
        }
    };

    return (
        <MainA className="b-add-in-wrapper">
            <Loader loading={loading} />
            <div className="heading-wrapper">
                <h1>Add-Ins</h1>
                <div className="buttons">
                    <Link className="margin-button" to="/activation-history">
                        <Button>Activation History</Button>
                    </Link>
                    <Link to="/module-usage">
                        <Button>Module Usage</Button>
                    </Link>
                </div>
            </div>
            <div className="wrapper">
                <div className='data'>
                    <div className="tabs-container">
                        <Button
                            className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
                            onClick={() => setActiveTab('all')}
                        >
                            All
                        </Button>
                        <Button
                            className={`tab-button ${activeTab === 'paid' ? 'active' : ''}`}
                            onClick={() => setActiveTab('paid')}
                        >
                            Paid
                        </Button>
                        <Button
                            className={`tab-button ${activeTab === 'free' ? 'active' : ''}`}
                            onClick={() => setActiveTab('free')}
                        >
                            Free
                        </Button>
                        <Button
                            className={`tab-button ${activeTab === 'active' ? 'active' : ''}`}
                            onClick={() => setActiveTab('active')}
                        >
                            Active
                        </Button>
                        <Button
                            className={`tab-button ${activeTab === 'inactive' ? 'active' : ''}`}
                            onClick={() => setActiveTab('inactive')}
                        >
                            Inactive
                        </Button>
                    </div>

                    <div className='labels'>
                        <div className='label'>
                            <span className='active'></span>
                            <p>Active</p>
                        </div>
                        <div className='label'>
                            <span className='inactive'></span>
                            <p>InActive</p>
                        </div>
                    </div>
                </div>

                <div className="addin-containers">
                    {filteredAddIns().length > 0 ? (
                        filteredAddIns().map((cc) => (
                            <div
                                key={cc.moduleId}
                                className={`container ${cc.clientModule[0]?.isActive ? 'active-module' : 'inactive-module'}`}
                                onClick={() => handleSubscriptionChange(cc)}
                                title={`Click to ${cc.clientModule[0]?.isActive ? 'Disable' : 'Enable'} Module`}
                            >
                                <div className="module-name">{cc.moduleName}</div>
                                <div className="margin-isPaid" style={{ color: cc.isPaid ? 'red' : 'green' }}>
                                    {cc.isPaid ? 'PAID' : 'FREE'}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No Modules found</div>
                    )}
                </div>
            </div>

            <Modal isOpen={isConfirmModalOpen} closeModal={() => setIsConfirmModalOpen(false)}>
                <div className="confirm-modal-content">
                    <h4>Are you sure you want to {selectedModule?.clientModule[0]?.isActive ? 'disable' : 'enable'} this module?</h4>
                    <div className="button-group">
                        <Button onClick={handleConfirmSubscriptionChange}>
                            Yes, {selectedModule?.clientModule[0]?.isActive ? 'Disable' : 'Enable'}
                        </Button>
                        <Button type="primary" onClick={() => setIsConfirmModalOpen(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </MainA>
    );
};

export default AddIn;
