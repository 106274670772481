import React, { useState, useEffect, useRef } from "react";
import arrowDown from "../../../assets/icons/purple-chevron-down.svg"; 
import './styles.scss'; 

const CustomDropdown = ({ options = [], label, onSelect, value, placeholder = "Select an option", className = ""  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value || null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        setSelectedOption(value)
    }, [value])

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        onSelect(option); 
        setIsOpen(false);
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <div className={`b-custom-dropdown ${className}`} ref={dropdownRef}>

            {label && <div className="dropdown-label">{label}</div>}
            <div className={`dropdown-header ${isOpen ? "open" : ""}`} onClick={toggleDropdown}>
                <span>{selectedOption ? selectedOption.label : placeholder}</span>

                <img
                    src={arrowDown}
                    alt="arrow icon"
                    className={`dropdown-icon ${isOpen ? "rotate" : ""}`}
                />
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    {options.length > 0 ? (
                        options.map((option, index) => (
                            <div
                                key={index}
                                className={`dropdown-item ${selectedOption && selectedOption.value === option.value ? "selected" : ""}`}
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option.label}
                            </div>
                        ))
                    ) : (
                        <div className="dropdown-item no-options">No options available</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
