import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { getRequest } from '../../utils/axios';

import Loader from "../../components/atoms/Loader";
import Table from "../../components/atoms/Table";
import MainA from '../../layout/Main Admin';
import Button from "../../components/atoms/Button";

import backArrow from '../../assets/icons/arrow-left.svg';

import './styles.scss';

const ClientData = () => {
    const { existingClientId } = useParams();
    const [loading, setLoading] = useState(true);
    const [featureUsage, setFeatureUsage] = useState([]);
    const [clientName, setClientName] = useState('');

    useEffect(() => {
        const fetchFeatureUsage = async () => {
            try {
                const response = await getRequest(`Client/GetDynamicFeatureUsage/${existingClientId}`);
                setFeatureUsage(response.data);

                if (response.data.length > 0) {
                    setClientName(response.data[0].clientName);
                }
            } catch (error) {
                console.error("Error fetching feature usage data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeatureUsage();
    }, [existingClientId]);

    return (
        <MainA className="b-client-data">
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <div className="wrapper">
                    <div className="head">
                        <h2>{clientName ? clientName : 'Client Data'}</h2>
                        <Button >
                            <Link to="/clients"> <img src={backArrow} alt='back arrow' /> Back  </Link>
                        </Button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th>Category Type Name</th>
                                <th>Category Status</th>
                                <th>Size</th>
                                <th>Page Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {featureUsage.map((item) => (
                                <tr key={item.clientId}>
                                    <td>{item.categoryTypeName ? item.categoryTypeName : '-'}</td>
                                    <td>{item.categoryStatus ? item.categoryStatus : '--'}</td>
                                    <td>
                                        {item.size > 0 ? (item.size / (1024 * 1024)).toFixed(2) + ' MB' : '-'}
                                    </td>
                                    <td className={item.categoryStatus === 'Deleted' ? 'clickable' : ''}>
                                        {item.categoryStatus === 'Deleted' ? (
                                            <Link to={`/client-deleted-images/${existingClientId}`}>
                                                {item.pageCount}
                                            </Link>
                                        ) : (
                                            item.pageCount
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </MainA>
    );
};

export default ClientData;
