import { Draggable } from 'react-beautiful-dnd';

const Task = ({task, index}) => {
    return (
        <Draggable draggableId={task.id} index={index}>
            {(provided) => (
                <div key={task.id} className='v-drag-object' 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    {task.content}
                </div>
            )}
            
        </Draggable>
    )
}

export default Task;