
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest, postAutoRequest, getToken, getDynamicHostName } from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { getCurrentUser } from '../../utils/utils';
import { Link } from 'react-router-dom';

import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Loader from '../../components/atoms/Loader';
import Modal from '../../components/atoms/Modal';

import backArrow from '../../assets/icons/arrow-left.svg'

import './styles.scss';

const MetaTag = () => {
    const { category: categoryName, pageUrl, page: pageId, categoryId: categoryId } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const emptyData = {
        pageId: null,
        metaTile: null,
        metaDescription: null,
        metaKeywords: null,
        metaRobots: null,
        metaGoogleBot: null,
        metaGoogleNoSiteLinksSearchBox: null,
        metaGoogleNoPageReadAloud: null,
        metaGoogleSiteVerification: null,
        metaRating: null,
        metaViewport: null,
        metaCharset: null,
        metaRefreshRedirect: null,
        oglocale: null,
        ogtype: null,
        ogtitle: null,
        ogdescription: null,
        ogurl: null,
        ogsiteName: null,
        ogimage: null,
        ogimageWidth: null,
        ogimageHeight: null,
        ogimageType: null,
        articlePublisher: null,
        articleModified: null,
        twitterCard: null,
        twitterTitle: null,
        twitterDescription: null,
        twitterImage: null,
        twitterSite: null,
        clientSmtpServer: null,
        clientSmtpPort: null,
        clientSmtpUser: null,
        clientSmtpPassword: null,
        clientSmtpSenderName: null,
        clientSmtpSenderEmail: null,
        clientSmtpToName: null,
        clientSmtpToEmail: null,
        isActive: false
    };

    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState('');
    const [editing, setEditing] = useState(false);
    const [data, setData] = useState(emptyData);
    const [userData, setUserData] = useState({});
    const [metaTagActive, setMetaTagActive] = useState(true);
    const [errors, setErrors] = useState({
        pageId: '',
        metaTile: '',
        metaDescription: '',
        metaKeywords: '',

        metaRobots: '',
        metaGoogleBot: '',
        metaGoogleNoSiteLinksSearchBox: '',
        metaGoogleNoPageReadAloud: '',
        metaGoogleSiteVerification: '',

        metaRating: '',
        metaViewport: '',
        metaCharset: '',
        metaRefreshRedirect: '',
        oglocale: '',
        ogtype: '',
        ogtitle: '',

        ogdescription: '',
        ogurl: '',
        ogsiteName: '',
        ogimage: '',
        ogimageWidth: '',
        ogimageHeight: '',

        ogimageType: '',
        articlePublisher: '',
        articleModified: '',
        twitterCard: '',

        twitterTitle: '',
        twitterDescription: '',
        twitterImage: '',
        twitterSite: '',
    });
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(emptyData);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin');
        }

        if (user) {
            setUserData({
                username: user?.clientSmtpServer,
                pageMetaTagId: user?.pageMetaTagId,
            });
        }
        getModules();
        getData();
        getCategories();
    }, [pageId]);

    const getCategories = async () => {
        const resp = await getRequest(`CategoryType/GetCategoryTypes`);
        setCategories(resp.data);
    }

    const getModules = async () => {
        setLoading(true);
        try {
            const resp = await getRequest(`Module/GetClientModules`);
            const data = resp.data;
            let isActive = true;
            data.forEach(d => {

                if (d.moduleId === 8) {
                    isActive = d.clientModule[0].isActive;
                }
            });

            setMetaTagActive(isActive);
        } catch (error) {
            console.error("Error fetching modules: ", error);
        } finally {
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            const response = await getRequest(`PageMetaTag/GetPageMetaTagByPageId/${pageId}`);
            if (response.status === 200) {
                setData(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching SMTP details:', error);
            setLoading(false);
        }
    }

    const handleGetMetaTags = async () => {
        setLoading(true);
        const token = getToken();
        const dynamicHostName = getDynamicHostName();

        const postObj = {
            "pageId": `${pageId}`,
            "url": `${dynamicHostName}${categoryName}/${pageUrl}`,
            "token": token,
            "api": "https://dynamicapi.memeticsolutions.in/api/Module/GetClientModules"
        };

        const response = await postAutoRequest(postObj);

        if (response.status === 200) {
            setEditing(true);
            setData({
                metaTile: response.data["meta-title"],
                metaDescription: response.data["meta-description"],
                metaKeywords: response.data["meta-keywords"],
            });
            NotificationManager.success('Success message', 'Meta Tags Generated Successfully');
        }

        setLoading(false);
    }

    const handleChange = (value, type) => {
        setData({
            ...data,
            [type]: value
        })
    }
    const handleBack = () => {
        if (categories) {
            categories.map((cc) => {
                return navigate(`/${categoryName}/${categoryId}`);
            });
        }
    };

    const handleSubmit = () => {
        handleSave();
    }

    const handleSave = async (existingData = {}) => {
        setLoading(true);
        let postObj = {
            pageId: pageId,
            metaTile: data.metaTile,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords,
            metaRobots: data.metaRobots,
            metaGoogleBot: data.metaGoogleBot,
            metaGoogleNoSiteLinksSearchBox: data.metaGoogleNoSiteLinksSearchBox,
            metaGoogleNoPageReadAloud: data.metaGoogleNoPageReadAloud,
            metaGoogleSiteVerification: data.metaGoogleSiteVerification,
            metaRating: data.metaRating,
            metaViewport: data.metaViewport,
            metaCharset: data.metaCharset,
            metaRefreshRedirect: data.metaRefreshRedirect,
            oglocale: data.oglocale,
            ogtype: data.ogtype,
            ogtitle: data.ogtitle,
            ogdescription: data.ogdescription,
            ogurl: data.ogurl,
            ogsiteName: data.ogsiteName,
            ogimage: data.ogimage,
            ogimageWidth: data.ogimageWidth,
            ogimageHeight: data.ogimageHeight,
            ogimageType: data.ogimageType,
            articlePublisher: data.articlePublisher,
            articleModified: data.articleModified,
            twitterCard: data.twitterCard,
            twitterTitle: data.twitterTitle,
            twitterDescription: data.twitterDescription,
            twitterImage: data.twitterImage,
            twitterSite: data.twitterSite


        }

        if (data.pageMetaTagId) {
            postObj.pageMetaTagId = data.pageMetaTagId
        }

        if (existingData.pageMetaTagId) {
            const postObj = {
                pageId: pageId,
                metaTile: existingData.metaTile || null,
                metaDescription: existingData.metaDescription || null,
                metaKeywords: existingData.metaKeywords || null,
                metaRobots: existingData.metaRobots || null,
                metaGoogleBot: existingData.metaGoogleBot || null,
                metaGoogleNoSiteLinksSearchBox: existingData.metaGoogleNoSiteLinksSearchBox || null,
                metaGoogleNoPageReadAloud: existingData.metaGoogleNoPageReadAloud || null,
                metaGoogleSiteVerification: existingData.metaGoogleSiteVerification || null,
                metaRating: existingData.metaRating || null,
                metaViewport: existingData.metaViewport || null,
                metaCharset: existingData.metaCharset || null,
                metaRefreshRedirect: existingData.metaRefreshRedirect || null,
                oglocale: existingData.oglocale || null,
                ogtype: existingData.ogtype || null,
                ogtitle: existingData.ogtitle || null,
                ogdescription: existingData.ogdescription || null,
                ogurl: existingData.ogurl || null,
                ogsiteName: existingData.ogsiteName || null,
                ogimage: existingData.ogimage || null,
                ogimageWidth: existingData.ogimageWidth || null,
                ogimageHeight: existingData.ogimageHeight || null,
                ogimageType: existingData.ogimageType || null,
                articlePublisher: existingData.articlePublisher || null,
                articleModified: existingData.articleModified || null,
                twitterCard: existingData.twitterCard || null,
                twitterTitle: existingData.twitterTitle || null,
                twitterDescription: existingData.twitterDescription || null,
                twitterImage: existingData.twitterImage || null,
                twitterSite: existingData.twitterSite || null
            };

        }

        const response = await postRequest(`PageMetaTag/PostPageMetaTag`, postObj);
        if (response.status === 200) {
            setEditing(false);
            setData(emptyData);
            getData();
        }
        else {
            console.error('Error: Unable to save data.');
        }
        setLoading(false);
    }

    const handlePopUp = () => {
        setConfirmModalOpen(true);
        setConfirmModalData();
    };

    const handleCloseCofirmationModal = () => {
        setConfirmModalData(emptyData);
        setConfirmModalOpen(false);
    }

    const handleConfirmModalYes = () => {
        handleGetMetaTags();
        setConfirmModalOpen(false);

    }

    return (
        <MainA active="whats-new" className="b-meta-tag-wrapper">
            <Loader loading={loading} />
            <Modal isOpen={confirmModalOpen} closeModal={handleCloseCofirmationModal} className="meta-modal" >
                <h4>Are you sure you want to generate Meta Tags Using Gpt? This would charge you xyz amount. </h4>
                <div className='modal-button'>
                    <Button onClick={handleConfirmModalYes}>Yes, Generate it</Button>
                    <Button onClick={handleCloseCofirmationModal}type="primary" >Discard</Button>
                </div>
            </Modal>
            {userType === 'admin' ?
                <div className='wrapper'>
                    <div className='heading-wrapper'>
                        <h1>Meta Tags</h1>
                        <div className='buttons'>
                            {metaTagActive ? (
                                <Button onClick={handlePopUp}>Generate Meta Tags using GPT</Button>
                            ) : ''}
                            <Link className="margin-button" to={`/existing-gpt-meta-tags/${pageId}`}>
                                <Button>See Meta Tags Generated using GPT</Button>
                            </Link>
                            <Button onClick={handleBack}> <img src = {backArrow} alt='back arrow' /> Back</Button>
                        </div>

                    </div>

                    <div className='form-wrapper'>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Title</label>
                                <Input type="text" value={data.metaTile} onChange={(e) => handleChange(e.target.value, 'metaTile')} placeholder="Enter Meta Title here..." />
                            </div>
                            <div className='row'>
                                <label>Meta Keywords</label>
                                <Input type="text" value={data.metaKeywords} onChange={(e) => handleChange(e.target.value, 'metaKeywords')} placeholder="Enter Meta Keywords here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Description</label>
                                <textarea value={data.metaDescription} onChange={(e) => handleChange(e.target.value, 'metaDescription')} placeholder="Enter Meta Description here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Robots</label>
                                <Input type="text" value={data.metaRobots} onChange={(e) => handleChange(e.target.value, 'metaRobots')} placeholder="Enter Meta Robots here..." />
                            </div>
                            <div className='row'>
                                <label>Meta Google Bot</label>
                                <Input type="text" value={data.metaGoogleBot} onChange={(e) => handleChange(e.target.value, 'metaGoogleBot')} placeholder="Enter Meta Google Bot here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Google No Site Links Search Box</label>
                                <Input type="text" value={data.metaGoogleNoSiteLinksSearchBox} onChange={(e) => handleChange(e.target.value, 'metaGoogleNoSiteLinksSearchBox')} placeholder="Enter Meta Google No Site Links Search Box here..." />

                            </div>
                            <div className='row'>
                                <label>Meta Google No Page Read Aloud</label>
                                <Input type="text" value={data.metaGoogleNoPageReadAloud} onChange={(e) => handleChange(e.target.value, 'metaGoogleNoPageReadAloud')} placeholder="Enter Meta Google No Page Read Aloud here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Rating</label>
                                <Input type="text" value={data.metaRating} onChange={(e) => handleChange(e.target.value, 'metaRating')} placeholder="Enter Meta Rating here..." />

                            </div>
                            <div className='row'>
                                <label>Meta Viewport</label>
                                <Input type="text" value={data.metaViewport} onChange={(e) => handleChange(e.target.value, 'metaViewport')} placeholder="Enter Meta Viewport here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Google Site Verification</label>
                                <Input type="text" value={data.metaGoogleSiteVerification} onChange={(e) => handleChange(e.target.value, 'metaGoogleSiteVerification')} placeholder="Enter Meta Google Site Verification here..." />
                            </div>
                            <div className='row'>
                                <label>Meta Charset</label>
                                <Input type="text" value={data.metaCharset} onChange={(e) => handleChange(e.target.value, 'metaCharset')} placeholder="Enter Meta Charset here..." />
                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Meta Refresh Redirect</label>
                                <Input type="text" value={data.metaRefreshRedirect} onChange={(e) => handleChange(e.target.value, 'metaRefreshRedirect')} placeholder="Enter Meta Refresh Redirect here..." />

                            </div>
                            <div className='row'>
                                <label>OG Locale</label>
                                <Input type="text" value={data.oglocale} onChange={(e) => handleChange(e.target.value, 'oglocale')} placeholder="Enter OG Locale here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>OG Type</label>
                                <Input type="text" value={data.ogtype} onChange={(e) => handleChange(e.target.value, 'ogtype')} placeholder="Enter OG Type here..." />

                            </div>
                            <div className='row'>
                                <label>OG Title</label>
                                <Input type="text" value={data.ogtitle} onChange={(e) => handleChange(e.target.value, 'ogtitle')} placeholder="Enter OG Title here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>OG Description</label>
                                <Input className="multiline-input" type="textarea" value={data.ogdescription} onChange={(e) => handleChange(e.target.value, 'ogdescription')} placeholder="Enter OG Description here..." />

                            </div>
                            <div className='row'>
                                <label>OG URL</label>
                                <Input type="text" value={data.ogurl} onChange={(e) => handleChange(e.target.value, 'ogurl')} placeholder="Enter OG URL here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>OG Site Name</label>
                                <Input type="text" value={data.ogsiteName} onChange={(e) => handleChange(e.target.value, 'ogsiteName')} placeholder="Enter OG Site Name here..." />

                            </div>
                            <div className='row'>
                                <label>OG Image</label>
                                <Input type="text" value={data.ogimage} onChange={(e) => handleChange(e.target.value, 'ogimage')} placeholder="Enter OG Image here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>OG Image Width</label>
                                <Input type="text" value={data.ogimageWidth} onChange={(e) => handleChange(e.target.value, 'ogimageWidth')} placeholder="Enter OG Image Width here..." />

                            </div>
                            <div className='row'>
                                <label>OG Image Height</label>
                                <Input type="text" value={data.ogimageHeight} onChange={(e) => handleChange(e.target.value, 'ogimageHeight')} placeholder="Enter OG Image Height here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>OG Image Type</label>
                                <Input type="text" value={data.ogimageType} onChange={(e) => handleChange(e.target.value, 'ogimageType')} placeholder="Enter OG Image Type here..." />

                            </div>
                            <div className='row'>
                                <label>Article Publisher</label>
                                <Input type="text" value={data.articlePublisher} onChange={(e) => handleChange(e.target.value, 'articlePublisher')} placeholder="Enter Article Publisher here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>Article Modified</label>
                                <Input type="date" value={data.articleModified} onChange={(e) => handleChange(e.target.value, 'articleModified')} placeholder="Enter Article Modified Date here..." />

                            </div>




                            <div className='row'>
                                <label>twitterCard </label>
                                <Input type="text" value={data.twitterCard} onChange={(e) => handleChange(e.target.value, 'twitterCard')} placeholder="Enter Article Modified Date here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>twitterTitle </label>
                                <Input type="text" value={data.twitterTitle} onChange={(e) => handleChange(e.target.value, 'twitterTitle')} placeholder="Enter Article Modified Date here..." />

                            </div>
                            <div className='row'>
                                <label>twitterDescription </label>
                                <Input type="text" value={data.twitterDescription} onChange={(e) => handleChange(e.target.value, 'twitterDescription')} placeholder="Enter Article Modified Date here..." />

                            </div>
                        </div>
                        <div className='dual'>
                            <div className='row'>
                                <label>twitterImage </label>
                                <Input type="text" value={data.twitterImage} onChange={(e) => handleChange(e.target.value, 'twitterImage')} placeholder="Enter Article Modified Date here..." />

                            </div>
                            <div className='row'>
                                <label htmlFor="twitterSite">Twitter Site</label>
                                <Input
                                    id="twitterSite"
                                    type="text"
                                    value={data.twitterSite}
                                    onChange={(e) => handleChange(e.target.value, 'twitterSite')}
                                    placeholder="Enter Twitter Site here..."
                                />
                            </div>
                        </div>

                        <div>
                            <Button onClick={handleSubmit} >Save</Button>
                        </div>
                    </div>


                </div>
                : 'Page Not Found'

            }
        </MainA>
    );
}

export default MetaTag;