import React from 'react';
import { PropTypes } from "prop-types";

import './styles.scss';

const Input = ({className, type, placeholder, value, onChange, suffix, checked, partiallyChecked, ...rest}) => {
  return (
    <div className='v-input-wrapper'>
      <input
          className={`v-input ${className} ${suffix ? 'hasSuffix' : ''}`}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          checked={checked}
          {...rest}
      />
      {suffix && (<div className='suffix'>{suffix}</div>)}
      {partiallyChecked && (<div onClick={onChange} className='partially-checked' > - </div>)}
    </div>
    
  );
}

Input.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.any,
}

Input.defaultProps = {
    className: '',
    type: 'text',
    placeholder: '',
    value: '',
    onChange: () => {},
}

export default Input;