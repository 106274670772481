import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './styles.scss';
import Input from '../components/atoms/Input';
import Image from '../components/atoms/Image';
import Button from '../components/atoms/Button';
import ErrorMsg from '../components/atoms/ErrorMsg';
import { getRequest } from '../utils/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock, faTrash } from '@fortawesome/free-solid-svg-icons'
import logoMemeticDark from '../assets/images/logo-memetic-dark.svg';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { getClientHomeImage } from "../utils/utils";
import { Link } from 'react-router-dom';

const SignIn = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')).token || '' : '');
    const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/clientimages/";
    const clientHomeImage = getClientHomeImage();
    const [errors, setErrors] = useState({
        code: '',
        password: '',
        email: '',
        response: '',
    });

    useEffect(() => {
        if (token) {

            navigate('/');
        }
    }, [token, navigate]);

    const handleSignin = async () => {
        const resp = await getRequest(`Client/GetClientToken/${code}`);
        if (resp.status === 200 && resp.data) {
            localStorage.setItem("token", JSON.stringify(resp.data));
            setToken(resp.data);
            NotificationManager.success('Success message', 'Congratulations, You are a valid Client');
        } else {
            setErrors({ code: 'Invalid Client Code' });
        }
    }

    const handleAdminSignin = async () => {
        const resp = await getRequest(`User/Authenticate/${email}/${password}?password=${password}`);
        if (resp.status === 200 && resp.data) {
            localStorage.setItem("token", JSON.stringify(resp.data));
            const userId = resp?.data?.userId;
            if (userId > 0) {
                const res = await getRequest(`User/GetUserDetail/${userId}`);
                if (res.status === 200 && res.data) {
                    localStorage.setItem("currentUser", JSON.stringify(res.data));
                    navigate('/category');
                }
            }
        } else {
            setErrors({ ...errors, response: resp?.response?.data ? "Invalid Username and Password" : '' });
        }
    }
    const handleRemoveLocalStorage = () => {
        localStorage.clear();
        window.location.reload();
    };
    const handleValidation = () => {
        const errorObj = {};
        if (!token) {
            if (!code) {
                errorObj.code = 'Please enter client code';
            }
        }
        if (token) {
            if (password === '') {
                errorObj.password = 'Please enter Password';
            }
            if (email === '') {
                errorObj.email = 'Please enter Email/Username';
            }
        }
        setErrors({ ...errors, ...errorObj });

        const validated = token ? (email && password) : code;
        return validated;
    }


    const handleSubmit = () => {
        const validated = handleValidation();
        if (validated) {
            if (token) {
                handleAdminSignin();
            } else {
                handleSignin();
            }
        }
    }

    return (
        <div className="sign-in-wrapper">
            <div className="part-one-signIn">
                <div className='form-wrapper'>
                    <div className='school-details-wrapper'>
                        <Image className="margin.bottom" src={token ? `${imageUrl}${clientHomeImage}` : logoMemeticDark} alt="logo" width={200} />
                    </div>
                    <div className='login-details'>
                        {token ? <h1 className="font-black">Admin Login</h1> : <h1 className="font-black">Client Code</h1>}
                        <div className='id-pass'>
                            {!token && <Input value={code} onChange={(e) => { setCode(e.target.value) }} suffix={<FontAwesomeIcon icon={faLock} />} placeholder='Client Code' />}
                            {!token && errors.code && (
                                <ErrorMsg>{errors.code}</ErrorMsg>
                            )}
                            {token && <Input value={email} onChange={(e) => { setEmail(e.target.value) }} suffix={<FontAwesomeIcon icon={faEnvelope} />} placeholder='Username' />}
                            {token && errors.email && (
                                <ErrorMsg>{errors.email}</ErrorMsg>
                            )}
                            {token && <Input type='password' value={password} onChange={(e) => { setPassword(e.target.value) }} suffix={<FontAwesomeIcon icon={faLock} />} placeholder='Password' />}
                            {token && errors.password && (
                                <ErrorMsg>{errors.password}</ErrorMsg>
                            )}
                            {errors.response && (
                                <ErrorMsg>{errors.response}</ErrorMsg>
                            )}
                        </div>
                    </div>
                    <Button onClick={() => handleSubmit()}>{token ? 'Sign In' : 'Verify'}</Button>

                </div>
            </div>
            <div className="part-two-signIn mask2" >

                {token ? <Link className="clear-button" title="Clear Cache" onClick={handleRemoveLocalStorage}>

                    <FontAwesomeIcon icon={faTrash} />
                </Link>
                    : null}


            </div>
        </div>
    );
}

export default SignIn;
