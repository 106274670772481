import React from 'react';
import './styles.scss';

const ToggleSwitch = ({ checked, onChange }) => {
  return (
    <label className="b-toggle-switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
};

export default ToggleSwitch;
