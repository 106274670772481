import React from 'react';
import { PropTypes } from "prop-types";

import './styles.scss';

const Button = ({className, type, disabled, onClick, children, ...rest}) => {
  return (
    <button
        className={`b-button ${className} ${type}`}
        disabled={disabled}
        onClick={onClick}
        {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
}

Button.defaultProps = {
    className: '',
    type: '',
    disabled: false,
    onClick: () => {},
    children: <></>
}

export default Button;