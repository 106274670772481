
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import { getCurrentUser } from '../../utils/utils';

import Table from '../../components/atoms/Table'
import MainA from '../../layout/Main Admin';
import Anchor from '../../components/atoms/Anchor';
import Dropdown from '../../components/atoms/Dropdown';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Loader from '../../components/atoms/Loader';
import CustomEditor from '../../components/atoms/CustomEditor';
import CustomDropdown from '../../components/atoms/CustomDropdown';
import FunctionalDropdown from '../../components/atoms/FunctionalDropdown';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Modal from '../../components/atoms/Modal';

import plus from '../../assets/icons/plus.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';
import edit from '../../assets/icons/edit.svg';
import copy from '../../assets/icons/copy.svg';

import './styles.scss';

const Component = () => {

    const emptyData = {
        componentCode: '',
        componentName: '',
        componentTypeId: '',
        description: '',
    };
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState('');
    const [editing, setEditing] = useState(false);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState(emptyData);
    const [userData, setUserData] = useState({});
    const [componentsData, setComponentsData] = useState([]);
    const [componentData, setComponentData] = useState([]);
    const [categories, setCategories] = useState([]);

    const [errors, setErrors] = useState({
        componentCode: '',
        componentName: '',
        componentTypeId: '',
        description: '',
        componentId: '',
    });
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateModalData, setDuplicateModalData] = useState(emptyData);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin')
        }
        if (user) {
            setUserData({
                username: user?.componentName,
                pageId: user?.componentId,
            })
        }
        getCategories();
        getData();
        getComponent();
    }, [])
    const getCategories = async () => {
        const resp = await getRequest(`CategoryType/GetCategoryTypes`);
        setCategories(resp.data);
    }
    const toggleDropdown = (id) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id));
    };
    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`ComponentType/GetComponentType`);
        setComponentsData(resp.data);

        setLoading(false);
    }

    const getComponent = async () => {
        setLoading(true);
        const resp = await getRequest(`Component/GetComponents`);
        setComponentData(resp.data);
        setLoading(false);
    }


    const handleAdd = () => {
        setData(emptyData);
        setErrors({});
        setEditing(true);
    }

    const handleEdit = (data) => {
        setEditing(true);
        setData({
            componentCode: data.componentCode,
            componentName: data.componentName,
            componentTypeId: data.componentTypeId,
            description: data.description,
            componentId: data.componentId,
        })
        getComponent();
        setLoading(false);
    }
    const handleDuplicate = async (componentId) => {
        const resp = await getRequest(`Component/Duplicate/${componentId}`);
        setData(resp.data);
        getComponent();
    }

    const handleValidation = () => {
        const errorObj = {};
        if (data.componentCode === '') {
            errorObj.componentCode = 'Please enter Component Code';
        } else {
            errorObj.componentCode = '';
        }
        if (data.componentName === '') {
            errorObj.componentName = 'Please enter Component Name';
        } else {
            errorObj.componentName = '';
        }
        if (data.componentTypeId === '') {
            errorObj.componentTypeId = 'Please enter Component Type Id';
        } else {
            errorObj.componentTypeId = '';
        }
        if (data.description === '') {
            errorObj.description = 'Please enter Description';
        } else {
            errorObj.description = '';
        }


        setErrors({ ...errors, ...errorObj })

        const validated = data.componentCode && data.componentName && data.componentTypeId && data.description;
        return validated;
    }

    const handleBack = () => {

        setErrors({});
        setEditing(false);

    }
    const handleChange = (value, type) => {
        setData({
            ...data,
            [type]: value
        })
    }

    const handleSubmit = () => {
        const validated = handleValidation();

        if (validated) {
            handleSave();
        }
    }

    const handleReset = (e) => {
        e.preventDefault();
        setData(emptyData);
        setErrors({});
    }

    const handleSave = async (existingData = {}) => {
        setLoading(true);
        let postObj = {
            componentCode: data.componentCode,
            componentName: data.componentName,
            componentTypeId: data.componentTypeId,
            description: data.description,
            componentId: data.componentId
        }

        if (data.componentId) {
            postObj.componentId = data.componentId
        }

        if (existingData.componentId) {
            postObj = {
                componentCode: existingData.componentCode,
                componentName: existingData.componentName,
                componentTypeId: existingData.componentTypeId,
                description: existingData.description,
                componentId: existingData.componentId
            }
        }

        const response = await postRequest(`Component/PostComponent`, postObj);
        if (response.status === 200) {
            setEditing(false);
            setData(emptyData);
            getComponent();
        }
        setLoading(false);
    }

    const handleDuplicateEntity = (data) => {

        setDuplicateModalOpen(true);
        setDuplicateModalData(data);
    };
    const handleCloseDuplicateModal = () => {
        setDuplicateModalData(emptyData);
        setDuplicateModalOpen(false);
    }

    const handleDuplicateModalYes = async () => {
        await handleDuplicate(duplicateModalData.componentId);
        setDuplicateModalOpen(false);
    }

    return (
        <MainA className="b-component-wrapper">
            <Loader loading={loading} />

            <Modal isOpen={duplicateModalOpen} closeModal={handleCloseDuplicateModal}>
                <h4>Are you sure you want to make dupicate of entity?</h4>
                <div className='v-modal-button-wrapper'>
                    <Button onClick={handleDuplicateModalYes}>Yes, Duplicate it</Button>
                    <Button onClick={handleCloseDuplicateModal}type="primary">Discard</Button>
                </div>
            </Modal>

            {userType === 'admin' ?
                <div className='component-wrapper'>
                    <div className='heading-wrapper'>
                        <h1>Components</h1>
                        {editing ? (
                            <div className="button-dropdown-wrapper">
                                <FunctionalDropdown
                                    className="custom-dropdown-variant"
                                    value={data.componentTypeId}
                                    options={componentsData.map((wnd) => ({
                                        label: wnd.componentTypeName,
                                        value: wnd.componentTypeId,
                                    }))}
                                    placeholder="Select-Type"
                                    onSelect={(option) => handleChange(option.value, 'componentTypeId')}
                                />
                                <Button onClick={handleBack}>
                                    <img src={leftArrow} alt='left arrow' /> Back
                                </Button>
                            </div>
                        ) : (
                            <div className="button-dropdown-container">

                                <div className="dropdown-wrapper">
                                    <p>Show</p>
                                    <CustomDropdown
                                        options={[
                                            { label: '10', value: 10 },
                                            { label: '20', value: 20 },
                                            { label: '50', value: 50 },
                                        ]}
                                        onSelect={(option) => setRowsPerPage(option.value)}
                                        value={{ label: rowsPerPage.toString(), value: rowsPerPage }}
                                        placeholder="Rows"
                                    />
                                </div>

                                <Button onClick={handleAdd}>
                                    <img src={plus} alt="plus icon" /> Add
                                </Button>

                            </div>
                        )}
                    </div>

                    {editing ? (
                        <div className='component-edit-wrapper'>
                            <div className='dual'>
                                <div className='row'>
                                    <label>Component Code</label>
                                    <Input type="text" value={data.componentCode} onChange={(e) => handleChange(e.target.value, 'componentCode')} placeholder="Enter Component Code here..." />
                                    {errors.componentCode && (
                                        <ErrorMsg className="v-errorMsg">{errors.componentCode}</ErrorMsg>
                                    )}
                                </div>
                                <div className='row'>
                                    <label>Component Name</label>
                                    <Input type="text" value={data.componentName} onChange={(e) => handleChange(e.target.value, 'componentName')} placeholder="Enter Component Name here..." />
                                    {errors.componentName && (
                                        <ErrorMsg className="v-errorMsg">{errors.componentName}</ErrorMsg>
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <label>Component Description</label>

                                <CustomEditor
                                    data={data.description}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        handleChange(data, 'description')
                                    }}
                                />
                                {errors.description && (
                                    <ErrorMsg className="v-errorMsg">{errors.description}</ErrorMsg>
                                )}
                            </div>

                            <div className='buttons'>
                                <Button onClick={handleSubmit} >Save</Button>
                                <Button onClick={handleReset}>Reset</Button>
                                <Button onClick={handleBack} className="primary" >Cancel</Button>
                            </div>
                        </div>
                    ) : (
                        <Table pagination={componentData.length > rowsPerPage}
                            pageSize={rowsPerPage}>
                            <thead>
                                <tr>
                                    <th>Component Name</th>
                                    <th>Component Code</th>
                                    <th>Component Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {componentData?.map((wnd) => (
                                    <tr key={wnd.componentId}>
                                        <td>{wnd.componentName}</td>
                                        <td>{wnd.componentCode}</td>
                                        <td>{wnd.componentType.componentTypeName}</td>
                                        <td>
                                            <Dropdown
                                                isOpen={openDropdownId === wnd.componentId}
                                                toggleDropdown={() => toggleDropdown(wnd.componentId)}
                                            >
                                                <Anchor onClick={() => handleEdit(wnd)}> <img src={edit} alt='edit icon' /> Edit</Anchor>
                                                <Anchor onClick={() => handleDuplicateEntity(wnd)}> <img src={copy} alt='copy icon' /> Duplicate</Anchor>
                                            </Dropdown>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                </div>
                : 'Page Not Found'
            }
        </MainA>
    );
}

export default Component;