import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { postRequest, getRequest } from '../../utils/axios';
import { NotificationManager } from 'react-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import MainA from '../../layout/Main Admin';
import Image from "../../components/atoms/Image";
import Input from '../../components/atoms/Input';
import Upload from '../../components/atoms/Upload';
import Button from '../../components/atoms/Button';
import Anchor from '../../components/atoms/Anchor';
import Loader from '../../components/atoms/Loader';
import Modal from '../../components/atoms/Modal';
import Table from '../../components/atoms/Table';
import FunctionalDropdown from '../../components/atoms/FunctionalDropdown';

import uploadImage from '../../assets/icons/upload_image.svg';
import plus from '../../assets/icons/plus.svg';

import './styles.scss';

const Gallery = () => {
    const [setPageName] = useState('');
    const { page } = useParams();
    const [galleryData, setGalleryData] = useState([]);
    const [galleryYearMonthData, setGalleryYearMonthData] = useState([]);
    const [galleryByYearMonthData, setGalleryByYearMonthData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({
        galleryFileTitle: '',
        fileName: '',
    });

    const emptyData = {
        galleryFileTitle: '',
        fileName: '',
    };

    const [galleryFileTitle, setGalleryFileTitle] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [resetFile, setResetFile] = useState(0);
    const [setErrors] = useState({});


    useEffect(() => {
        const storedPageName = sessionStorage.getItem('pageName');

        if (storedPageName) {
            setPageName(storedPageName);
            sessionStorage.removeItem('pageName');
        }
        getGalleryYearMonth();
        getCategories();
    }, [page]);

    const getGalleryYearMonth = async () => {
        setLoading(true);
        try {
            const resp = await getRequest('Gallery/GetGalleryYearMonth');
            const data = resp.data || [];

            const sortedData = data.sort((a, b) => {
                if (a.year === b.year) {
                    return b.monthNumber - a.monthNumber;
                }
                return b.year - a.year;
            });

            setGalleryYearMonthData(sortedData);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            NotificationManager.error('Failed to fetch gallery year/month data');
        }
    };


    const getGalleryByYearMonth = async (galleryFilePath) => {
        setLoading(true); 
        const path = galleryFilePath.replaceAll("\\", "-");
        try {
            const resp = await getRequest(`Gallery/GetGalleryByYearMonth/${path}`);
            const galleryData = resp.data.map(rd => {
                rd.galleryFilePath = galleryFilePath;
                return rd;
            });
    
            setGalleryByYearMonthData(galleryData || []);
        } catch (error) {
            NotificationManager.error('Failed to fetch gallery data by year/month');
        } finally {
            setLoading(false);
        }
    };

    const handleImageSelection = (data) => {
        if (selectedImage && selectedImage.galleryId === data.galleryId) {
            setSelectedImage(null);
        } else {
            setSelectedImage(data);
        }
    };

    const handleTableCheckboxChange = (e, rowdata) => {
        e.preventDefault();
        setConfirmModalOpen(true);
        setConfirmModalData(rowdata);
    };

    const isSelected = (image) => {
        return selectedImage && selectedImage.galleryId === image.galleryId;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('galleryFileTitle', galleryFileTitle);

        try {
            setActionLoading(true);
            const resp = await postRequest(`Gallery/PostImage`, formData);
            if (resp.status === 200) {
                getCategories();
                setGalleryFileTitle('');
                setImageFile('');
                setResetFile(resetFile + 1);
                NotificationManager.success('Success message', 'Image is uploaded Successfully');
                setIsAddImageModalOpen(false);
            } else {
                console.error('Failed to upload image:', resp);
                setErrors({ submitError: 'Failed to upload image' });
            }
        } catch (error) {
            console.error('Network error:', error);
            setErrors({ submitError: 'Network error occurred' });
        } finally {
            setActionLoading(false);
        }
    };

    const trimText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const handleMonthSelection = (data) => {
        if (data.galleryFilePath) {
            getGalleryByYearMonth(data.galleryFilePath);
        }
    };

    const groupedByYear = galleryYearMonthData.reduce((acc, item) => {
        if (!acc[item.year]) {
            acc[item.year] = [];
        }
        acc[item.year].push(item);
        return acc;
    }, {});

    const yearOptions = Object.keys(groupedByYear).map((year) => ({
        label: year,
        value: year,
    }));

    const getCategories = async () => {
        setLoading(true);
        try {
            const resp = await getRequest(`Gallery/GetGallery`);
            setGalleryData(resp.data.reverse());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Failed to fetch categories');
        }
    };

    const removeImage = async (galleryId) => {
        setActionLoading(true);
        try {
            await getRequest(`Gallery/Delete/${galleryId}`);
            getCategories();
        } catch (error) {
            NotificationManager.error('Failed to remove image');
        } finally {
            setActionLoading(false);
        }
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalData(emptyData);
        setConfirmModalOpen(false);
    };

    const handleConfirmModalYes = async () => {
        await removeImage(confirmModalData.galleryId);
        setConfirmModalOpen(false);
    };

    const handleUpload = (file) => {
        setImageFile(file);
        const fileName = file.name;
        if (galleryFileTitle === '') {
            setGalleryFileTitle(fileName);
        }
    };

    const handleGalleryFileTitleChange = (e) => {
        setGalleryFileTitle(e.target.value);
    };

    const handleYearChange = (selectedOption) => {
        debugger;
        setSelectedYear(selectedOption.value);
        setSelectedMonth('');

        const monthsForSelectedYear = groupedByYear[selectedOption.value]
            ?.map((data) => data.month)
            .sort((a, b) => {
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                return months.indexOf(b) - months.indexOf(a);
            });


        if (monthsForSelectedYear && monthsForSelectedYear.length > 0) {
            setSelectedMonth(monthsForSelectedYear[0]);

            const selectedData = galleryYearMonthData.find(
                (item) => item.year === parseInt(selectedOption.value) && item.month === monthsForSelectedYear[0]
            );

            if (selectedData) {
                handleMonthSelection(selectedData);
            }
        }
    };

    useEffect(() => {
        if (galleryYearMonthData.length > 0) {
            const sortedData = [...galleryYearMonthData].sort((a, b) => {
                if (a.year === b.year) {
                    return b.monthNumber - a.monthNumber;
                }
                return b.year - a.year;
            });
    
            const latestYear = sortedData[0].year;
            const latestMonth = sortedData[0].month;
    
            setSelectedYear(latestYear);
            setSelectedMonth(latestMonth);
    
            const latestEntry = galleryYearMonthData.find(
                (item) => item.year === latestYear && item.month === latestMonth
            );
            
            if (latestEntry) {
                handleMonthSelection(latestEntry);
            }
        }
    }, [galleryYearMonthData]);
    

    const handleMonthChange = (selectedOption) => {
        setSelectedMonth(selectedOption.value);

        const selectedData = galleryYearMonthData.find(
            (item) => item.year === parseInt(selectedYear) && item.month === selectedOption.value
        );

        if (selectedData) {
            handleMonthSelection(selectedData);
        }
    };

    function bytesToSize(bytes) {
        if (bytes < 1024) {
            return `${bytes} B(bytes)`;
        } else if (bytes < 1024 * 1024) {
            return `${(bytes / 1024).toFixed(2)} KB`;
        } else {
            return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
        }
    }

    return (
        <MainA className="b-gallery-list">
            {loading && <Loader loading={loading} />} 
            <Modal isOpen={confirmModalOpen} closeModal={handleCloseConfirmationModal}>
                <div className='delete-modal'>
                    <div className='circle'>!</div>
                    <h4>Are you sure you want to remove the image?</h4>
                    <div className='v-modal-button-wrapper'>
                        <Button onClick={handleConfirmModalYes}>Yes, Remove image</Button>
                        <Button onClick={handleCloseConfirmationModal} type="primary">Cancel</Button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isAddImageModalOpen} closeModal={() => setIsAddImageModalOpen(false)}>
                <div className="gallery-add-image">
                    <img src={uploadImage} alt="" />
                    <form onSubmit={handleSubmit}>
                        <div className="upload-image-form">
                            <div className='enter-name'>
                                <label htmlFor="galleryFileTitle">Image Name:</label>
                                <Input
                                    id="galleryFileTitle"
                                    type="text"
                                    value={galleryFileTitle}
                                    onChange={handleGalleryFileTitleChange}
                                    required
                                />
                            </div>
                            <div className='adjust-width'>
                                <label htmlFor="image">Upload Image:</label>
                                <Upload
                                    onChange={handleUpload}
                                    allowedFiles=".png,.jpg,.jpeg,.svg"
                                    resetFile={resetFile}
                                />
                            </div>
                            <Button className="gallery-btn" type="submit" disabled={actionLoading}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>

            <div className="gallery-wrapper">
                <div className="heading-wrapper">
                    <div className='upper-section'>
                        <Button onClick={() => setIsAddImageModalOpen(true)} className="add-image-btn">
                            <img src={plus} alt='add icon' />  Add Image
                        </Button>
                        <div className='dropdowns'>
                            <FunctionalDropdown
                                value={selectedYear}
                                onSelect={handleYearChange}
                                options={yearOptions}
                                placeholder={selectedYear || 'Select Year'}
                                className='options year-dropdown'
                            />
                            <FunctionalDropdown
                                value={selectedMonth}
                                onSelect={handleMonthChange}
                                options={groupedByYear[selectedYear]?.map((data) => ({
                                    label: data.month,
                                    value: data.month,
                                })) || []}
                                placeholder={selectedMonth || 'Select Month'}
                                className='options month-dropdown'
                            />
                        </div>

                    </div>
                    {selectedImage ? (
                        <div children="lower-section">
                            <div className='upload-and-detail'>
                                <Table>
                                    <Loader loading={loading} />
                                    <thead>
                                        <tr>
                                            <th> Image Name </th>
                                            <th>Image Path</th>
                                            <th>Dimensions (W x H)</th>
                                            <th>Size</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedImage ? (
                                            <>
                                                <tr key={selectedImage.galleryId}>
                                                    <td>
                                                        <Anchor
                                                            to={`${imageUrl}/${selectedImage.galleryFilePath ? selectedImage.galleryFilePath.replace(/\\/g, '/') : ''}${selectedImage.galleryFileName || ''}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {selectedImage.galleryFileTitle}
                                                        </Anchor>
                                                    </td>
                                                    <td className="file-path-cell truncate" title={`${imageUrl}/${selectedImage.galleryFilePath.replace(/\\/g, '/')}${selectedImage.galleryFileName}`}>
                                                        <CopyToClipboard
                                                            className="copy-btn"
                                                            text={`${imageUrl}/${selectedImage.galleryFilePath.replace(/\\/g, '/')}${selectedImage.galleryFileName}`}
                                                            onCopy={() => { NotificationManager.success('Copied to clipboard!'); }}
                                                        >
                                                            <FontAwesomeIcon icon={faCopy} />
                                                        </CopyToClipboard>
                                                        {`${imageUrl}/${selectedImage.galleryFilePath.replace(/\\/g, '/')}${selectedImage.galleryFileName}`}
                                                    </td>
                                                    <td>
                                                        {galleryData.find(img => img.galleryId === selectedImage.galleryId)?.galleryFileWidth && galleryData.find(img => img.galleryId === selectedImage.galleryId)?.galleryFileHeight
                                                            ? `${galleryData.find(img => img.galleryId === selectedImage.galleryId).galleryFileWidth} x ${galleryData.find(img => img.galleryId === selectedImage.galleryId).galleryFileHeight}`
                                                            : 'Dimensions not available'}
                                                    </td>
                                                    <td>
                                                        {galleryData.find(img => img.galleryId === selectedImage.galleryId) ?
                                                            bytesToSize(`${galleryData.find(img => img.galleryId === selectedImage.galleryId).galleryFileSize}`)
                                                            : 'Dimensions not available'}
                                                    </td>
                                                    <td>
                                                        <Button className="gallery-btn" onClick={(e) => handleTableCheckboxChange(e, selectedImage)} disabled={actionLoading}>Delete</Button>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={5}><h5 className="centered-text">Select an image for details</h5></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className="images-container">
                    <div className="images-list">
                        {galleryByYearMonthData.map((wnd) => (
                            <div
                                key={wnd.galleryId}
                                className={`image ${isSelected(wnd) ? 'selected' : ''}`}
                                onClick={() => handleImageSelection(wnd)}
                            >
                                <div className={`selected-image${isSelected(wnd) ? 'selected' : ''}`}>
                                    <Image
                                        src={`${imageUrl}/${wnd?.galleryFilePath ? wnd.galleryFilePath.replace(/\\/g, '/') : ''}${wnd.galleryFileName || ''}`}
                                        className="uploaded-image"
                                    />
                                </div>
                                <div className="file-path-cell" title={wnd.galleryFileTitle}>
                                    {trimText(wnd.galleryFileTitle, 30)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </MainA>
    );
};

export default Gallery;
