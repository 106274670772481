import React, { useEffect, useState } from 'react';
import { PropTypes } from "prop-types";

import Button from '../Button';

import arrowLeft from '../../../assets/icons/chevron-left.svg';
import arrowRight from '../../../assets/icons/chevron-right.svg';

import './styles.scss';

const Table = ({ className, children, pagination, selectedPage, onPageChange, pageSize, categoryName, ...rest }) => {
  const [totalPages, setTotalPage] = useState('');
  const [page, setPage] = useState(selectedPage || 1);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (pagination) {
      paginationGenerator();
    }
  }, [children]);

  const paginationGenerator = () => {
    const rows = document.querySelectorAll('.v-table tbody tr');
    const totalRows = rows.length;
    const totalPages = Math.ceil(totalRows / pageSize);

    setPage(selectedPage || 1);
    setTotalPage(totalPages);
    setTotalRows(totalRows);

    rowManagement(selectedPage || 1);
  };

  const rowManagement = (page) => {
    const rows = document.querySelectorAll('.v-table tbody tr');
    rows.forEach((r, i) => {
      r.classList.remove('hide');
      if (i >= (page * pageSize) || i < (pageSize * (page - 1))) {
        r.classList.add('hide');
      }
    });
  };

  const handleNext = () => {
    setPage(page + 1);
    onPageChange(page + 1);
    rowManagement(page + 1);
  };

  const handlePrev = () => {
    setPage(page - 1);
    onPageChange(page - 1);
    rowManagement(page - 1);
  };

  const handlePageClick = (page) => {
    setPage(page);
    onPageChange(page);
    rowManagement(page);
  };

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const showingFrom = totalRows === 0 ? 0 : indexOfFirstItem + 1;
  const showingTo = indexOfLastItem > totalRows ? totalRows : indexOfLastItem;

  return (
    <div className={`v-table table-responsive ${className}`} {...rest}>
      <table>
        {children}
      </table>
      {pagination && (
        <div className='b-pagination'>
          <div className='count-show'>{`Showing ${showingFrom} to ${showingTo} of ${totalRows} ${categoryName}`}</div>
          <div className='numbers'>
            <Button disabled={page === 1} type="primary" onClick={handlePrev}>
              <img src={arrowLeft} alt="Previous" />
            </Button>
            {Array.from(Array(totalPages), (e, i) => {
              const isActive = page === i + 1;
              return (
                <Button
                  key={i + 1}
                  className={(i + 1 < page - 3 || i + 1 > page + 3) ? 'hide' : isActive ? 'active-page' : 'non-active-page'}
                  onClick={() => handlePageClick(i + 1)}
                >
                  {i + 1}
                </Button>
              );
            })}
            <Button disabled={page === totalPages} type="primary" onClick={handleNext}>
              <img src={arrowRight} alt="Next" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  pagination: PropTypes.bool,
  pageSize: PropTypes.any,
  onPageChange: PropTypes.func,
  categoryName: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  children: <></>,
  pagination: false,
  pageSize: 10,
  onPageChange: () => { },
  categoryName: 'Items',
};

export default Table;
