import React from 'react';
import './styles.scss'; // Ensure this includes the above CSS

const Offcanvas = ({ isOpen, closeOffcanvas, children }) => {
    return (
        <>
            <div className={`offcanvas-backdrop ${isOpen ? 'is-open' : ''}`} onClick={closeOffcanvas} />
            
            <div className={`b-offcanvas ${isOpen ? 'is-open' : ''}`}>
                <div className="offcanvas-content">
                    <button className="offcanvas-close" onClick={closeOffcanvas}>X</button>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Offcanvas;
