
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRequest } from '../../utils/axios';
import { getCurrentUser, getDate } from '../../utils/utils';

import Table from '../../components/atoms/Table'
import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import Loader from '../../components/atoms/Loader';

import './styles.scss';

const Queries = () => {
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState('');
    const [userData, setUserData] = useState({});
    const [queryData, setQueryData] = useState([]);
    const [filteredQueryData, setFilteredQueryData] = useState([]);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin')
        }
        if (user) {
            setUserData({
                username: user?.pageName,
                pageId: user?.pageId,
            })
        }

        getData()
    }, [])

    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`ClientModuleActivationHistory/GetModulesActivationHistory`);
        setQueryData(resp.data);
        setFilteredQueryData(resp.data);
        console.log(resp.data);
        setLoading(false);
    }

    const sortedData = filteredQueryData.sort((a, b) => {
        const dateA = new Date(a.activationDateTime);
        const dateB = new Date(b.activationDateTime);

        return dateB - dateA;
    });
    return (
        <MainA active="whats-new" className="b-activation-history">
            <Loader loading={loading} />
            <div className='wrapper'>
                <div className='heading-wrapper'>
                    <h1 className='heading-bottom-padding'>Module Activation History</h1>
                    <div>
                        <Link to="/addIns">
                            <Button type="primary">Back</Button>
                        </Link>
                    </div>
                </div>
                <Table pagination={true} pageSize={10}>
                    <thead>
                        <tr>
                            <th>Module Name</th>
                            <th>Activation Date</th>
                            <th>Deactivated Date</th>
                            <th>isActive</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredQueryData.length > 0 ? (
                            sortedData.map((wnd) => (
                                <tr key={wnd.clientId}>
                                    <td>{wnd.module.moduleName}</td>
                                    <td>{getDate(wnd.activationDateTime)}</td>
                                    <td>{getDate(wnd.deactivatedDateTime) ? getDate(wnd.deactivatedDateTime) : "Running"}</td>
                                    <td>
                                    <p className={wnd.isActive ? 'active-status' : 'inactive-status'}>
                                        {wnd.isActive ? 'Active' : 'Inactive'}
                                    </p>
                                </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9}><h5 className="centered-text">No History Found</h5></td>
                            </tr>
                        )}
                    </tbody>

                </Table>
            </div>
        </MainA>
    );
}

export default Queries;