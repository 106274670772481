import React from 'react';
import { PropTypes } from "prop-types";

const Image = ({src, alt, height, width, ...rest}) => {
  return (
    <img
        src={src}
        alt={alt}
        height={height}
        width={width}
        {...rest}
    />
  );
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
}

Image.defaultProps = {
    src: '',
    alt: 'image',
    height: 'auto',
    width: '100%'
}

export default Image;