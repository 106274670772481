import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, getDate } from '../../utils/utils';

import Table from '../../components/atoms/Table'
import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import Loader from '../../components/atoms/Loader';
import Modal from '../../components/atoms/Modal';

import arrowBack from '../../assets/icons/arrow-left.svg'

import './styles.scss';

const ExistingGptMetaTags = () => {
    const { page: pageId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState('');
    const [userData, setUserData] = useState({});
    const [queryData, setQueryData] = useState([]);
    const [filteredQueryData, setFilteredQueryData] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(null);

    useEffect(() => {
        let user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin')
        }
        if (user) {
            setUserData({
                username: user?.pageName,
                pageId: user?.pageId,
            })
        }
        getData()
    }, [])

    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`PageMetaTagsGpt/GetPageMetaTagsGptByPage/${pageId}`);
        setQueryData(resp.data);
        setFilteredQueryData(resp.data);
        setLoading(false);
    }

    const setMetaTags = async (pageMetaTagsGptid) => {
        setLoading(true);
        try {
            const resp = await getRequest(`PageMetaTagsGpt/UsePageMetaTagsGpt/${pageId}/${pageMetaTagsGptid}`);
        } catch (error) {
            console.error("Error using meta tags: ", error);
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleUseThis = (rowdata) => {
        setConfirmModalData(rowdata);
        setConfirmModalOpen(true);
    }

    const handleConfirmationModal = () => {
        setConfirmModalOpen(false);
    };

    const handleConfirmationModalNo = () => {
        setConfirmModalOpen(false);
    };

    const handleConfirmModalYes = () => {
        setMetaTags(confirmModalData.pageMetaTagsGptid);
        setConfirmModalOpen(false);
    };

    return (
        <MainA className="b-existing-meta-tags">
            <Loader loading={loading} />
            <Modal isOpen={confirmModalOpen} closeModal={handleConfirmationModal} className="meta-modal">

                <h4>Are you sure you want to Use these Meta Tags?</h4>
                <div className='v-modal-button-wrapper'>
                    <Button onClick={handleConfirmModalYes}>Yes, use this</Button>
                    <Button onClick={handleConfirmationModalNo} type="primary">No</Button>
                </div>

            </Modal>
            <div className='wrapper'>
                <div className='heading-wrapper'>
                    <h1>Existing Gpt Meta Tags</h1>
                    <Button onClick={handleBack}> <img src={arrowBack} alt='back arrow' /> Back</Button>
                </div>
                <Table pagination={true} pageSize={10}>
                    <thead>
                        <tr>
                            <th>Meta Title</th>
                            <th>Meta Description</th>
                            <th>Meta Keywords</th>
                            <th>Generation Date</th>
                            <th>Input Token</th>
                            <th>Output Token</th>
                            <th>Total Token</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredQueryData.length > 0 ? (
                            filteredQueryData.map((wnd) => (
                                <tr key={wnd.pageMetaTagsGptid}>
                                    <td>{wnd.metaTitle}</td>
                                    <td>{wnd.metaDescription}</td>
                                    <td>{wnd.metaKeywords}</td>
                                    <td>{getDate(wnd.lastUpdatedDateTime)}</td>
                                    <td>{wnd.inputToken}</td>
                                    <td>{wnd.outputToken}</td>
                                    <td>{wnd.totalToken}</td>
                                    <td><Button onClick={() => handleUseThis(wnd)}>Use This</Button></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8}><h5 className="centered-text">No History Found</h5></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </MainA>
    );
}

export default ExistingGptMetaTags;
