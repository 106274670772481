import React, { useEffect, useState } from 'react';
import './styles.scss';
import { getRequest, postRequest } from '../../utils/axios';
import MainA from '../../layout/Main Admin';
import { NotificationManager } from 'react-notifications';
import Loader from '../../components/atoms/Loader';
import Button from '../../components/atoms/Button';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './column';
import classNames from 'classnames';

const CustomizedPage = () => {
    const { categoryId, page: pageId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState(''); 
    const [templates, setTemplates] = useState([]);
    const [savedTemplateIds, setSavedTemplateIds] = useState([]);
    const [isTemplate, setTemplate] = useState(true);
    const [isTemplateSelected, setTemplateSelected] = useState(true);
    const [clickedTemplate, setClickedTemplate] = useState(null);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [savedComponents, setSavedComponents] = useState(null);

    useEffect(() => {
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin');
        }

        fetchExistingPageConfiguration();
        getTemplates();
    }, [categoryId]);

    const fetchExistingPageConfiguration = async () => {
        setLoading(true);
        try {
            const response = await getRequest(`PageComponent/GetPageComponentsById/${pageId}`);
            const existingConfig = response.data;
            const templateIds = existingConfig.map((config) => config.templateId);
            setSavedTemplateIds(templateIds);
            handleTemplateButtonClick(existingConfig[0], existingConfig);
            setSavedComponents(existingConfig);
        } catch (error) {
            console.error('Error fetching existing configuration:', error);
        }
        setLoading(false);
    };

    const getTemplates = async () => {
        const resp = await getRequest(`Template/GetTemplates`);
        setTemplates(resp.data);
    };

    const createDragDropData = (data, sections, savedData = null) => {
        if (!data || !sections) {
            console.error('Data or sections are missing:', { data, sections });
            return;
        }

        const obj = {
            tasks: {},
            columns: {
                'column-0': {
                    id: 'column-0',
                    title: 'Available Components',
                    taskIds: [],
                    css: 'column-0',
                },
            },
            columnOrder: ['column-0'],
        };

        sections.forEach((section, i) => {
            const columnId = `column-${section.sectionId}`;
            obj.columns[columnId] = {
                id: columnId,
                title: section.sectionName,
                taskIds: [],
                css: `column-${i + 1}`,
            };
            obj.columnOrder.push(columnId);
        });

        data.forEach((d, i) => {
            const filteredComponent = savedData?.filter((sd) => sd.componentId === d.componentId);
            const sectionId = filteredComponent?.length ? filteredComponent[0]?.sectionId : 0;
            const taskId = `task-${i}`;
            obj.tasks[taskId] = {
                id: taskId,
                content: d.componentName,
                value: d,
            };
            obj.columns[`column-${sectionId}`].taskIds.push(taskId);
        });

        setData(obj);
    };

    const removeComponentBeforeAdd = async () => {
        try {
            await getRequest(`PageComponent/DeletePageComponentsById/${pageId}`);
        } catch (error) {
            console.error('Error deleting existing components:', error);
        }
    };

    const handleBack = () => {
        navigate(`/pages/7`);
    };

    const dragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const isTemplate1Active = isTemplate;
        const activeData = isTemplate1Active ? data : data2;
        const setActiveData = isTemplate1Active ? setData : setData2;

        const start = activeData.columns[source.droppableId];
        const finish = activeData.columns[destination.droppableId];

        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                ...activeData,
                columns: {
                    ...activeData.columns,
                    [newColumn.id]: newColumn,
                },
            };

            setActiveData(newState);
            return;
        }

        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newState = {
            ...activeData,
            columns: {
                ...activeData.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        setActiveData(newState);
    };

    const handleSave = async (sections, template) => {
        setLoading(true);
        await removeComponentBeforeAdd();

        const postRequests = [];

        let count = 1;

        sections.forEach((section) => {
            const columnOrder = [`column-${section.sectionId}`];

            columnOrder.forEach((columnId) => {
                const column = data.columns[columnId];
                column.taskIds.forEach((taskId) => {
                    const task = data.tasks[taskId];
                    postRequests.push({
                        pageId: pageId,
                        componentId: task.value.componentId,
                        componentOrder: count,
                        templateId: template.templateId,
                        sectionId: section.sectionId,
                    });
                    count++;
                });
            });
        });

        try {
            const responses = await Promise.all(postRequests.map((postObj) => postRequest('PageComponent/PostPageComponent', postObj)));
            if (responses.every((response) => response.status === 200)) {
                NotificationManager.success('Page configuration saved successfully', 'Success');
            } else {
                NotificationManager.error('Failed to save configuration', 'Error');
            }
        } catch (error) {
            NotificationManager.error('Failed to save configuration', 'Error');
        }
        fetchExistingPageConfiguration();
        setLoading(false);
    };

    const getSectionsAndSave = () => {
        if (clickedTemplate) {
            handleSectionButtonClick(clickedTemplate);
        }
    };

    const handleSectionButtonClick = async (template) => {
        setLoading(true);
        try {
            const resp = await getRequest(`TemplateSection/GetTemplateSections/${template.templateId}`);
            const sections = resp.data;

            handleSave(sections, template);
        } catch (error) {
            console.error('Error fetching template sections or components:', error);
        }
        setLoading(false);
    };

    const handleTemplateButtonClick = async (template, savedData = null) => {
        setTemplateSelected(false);
        setClickedTemplate(template);
        setLoading(true);
        try {
            const resp = await getRequest(`TemplateSection/GetTemplateSections/${template.templateId}`);
            const sections = resp.data;

            const componentsResp = await getRequest('Component/GetComponents');
            const sortedComponents = componentsResp.data.sort((a, b) => a.componentTypeId - b.componentTypeId);

            createDragDropData(sortedComponents, sections, savedData);
        } catch (error) {
            console.error('Error fetching template sections or components:', error);
        }
        setLoading(false);
    };

    const getGridContainerClass = () => {
        if (!clickedTemplate) return 'grid-container';
        return `grid-container-${clickedTemplate.templateId}`;
    };

    const savedDataOnClick = (templateId, savedComponents) => {
        const matchedComponent = savedComponents.find((sc) => sc.templateId === templateId);
        return matchedComponent ? savedComponents : null;
    };

    return (
        <MainA active="expense-head-admin" className="individual-page-component">
            <Loader loading={loading} />
            <div className="heading-wrapper">
                <h1>Page Components</h1>
                <div className='clickables'>
                    {templates
                        .filter((tmp) => tmp.templateId === 1)
                        .map((tmp) => (
                            <Button
                                key={tmp.templateId}
                                onClick={() => handleTemplateButtonClick(tmp, savedDataOnClick(tmp.templateId, savedComponents))}
                                className={classNames('add-btn', {
                                    highlighted: clickedTemplate && tmp.templateId === clickedTemplate.templateId,
                                })}
                                type="primary"
                            >
                                {tmp.templateName}
                            </Button>
                        ))}
                    <Button onClick={handleBack} className="add-btn" type="primary">
                        Back
                    </Button>
                    
                    <div className="action-button">
                        {isTemplateSelected ? (
                            <div>Select Template</div>
                        ) : (
                            <Button onClick={getSectionsAndSave}>
                                Save
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            {isTemplate && (
                <div className="v-drag-drop-wrapper">

                    <div className={getGridContainerClass()}>
                        <DragDropContext onDragEnd={dragEnd}>
                            {data?.columnOrder.map((columnId) => {
                                const column = data.columns[columnId];
                                const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);

                                return <Column key={column.id} column={column} tasks={tasks} />;
                            })}
                        </DragDropContext>
                    </div>
                </div>
            )}
        </MainA>
    );
};

export default CustomizedPage;