import React, { useEffect, useState } from 'react';
import './styles.scss';
import { getRequest, postRequest } from '../../utils/axios';
import MainA from '../../layout/Main Admin';
import Loader from '../../components/atoms/Loader';
import Table from '../../components/atoms/Table';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import Input from '../../components/atoms/Input';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Anchor from '../../components/atoms/Anchor';
import plus from '../../assets/icons/plus.svg';
import remove from '../../assets/icons/delete.svg';
import edit from '../../assets/icons/edit.svg';
import CustomDropdown from '../../components/atoms/CustomDropdown';
import ToggleSwitch from '../../components/atoms/ToggleSwitch';


const VideoGallery = () => {
    const emptyData = {
        videoFileTitle: '',
        videoFilePath: '',
        isActive: false,
    };

    const [errors, setErrors] = useState(emptyData);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [videoData, setVideoData] = useState(emptyData);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({});
    const [users, setUsers] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    useEffect(() => {
        getVideoes();
    }, []);

    const handleValidation = () => {
        const errorObj = {};

        if (videoData.videoFileTitle === '') {
            errorObj.videoFileTitle = 'Please enter Video Title';
        }

        if (videoData.videoFilePath === '') {
            errorObj.videoFilePath = 'Please enter Youtube Link';
        }

        setErrors(errorObj);

        return videoData.videoFileTitle && videoData.videoFilePath;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            handleSave();
        }
    };

    const handleChange = (value, type) => {
        setVideoData({
            ...videoData,
            [type]: value
        });
    };

    const removeVideo = async (videoId) => {
        setLoading(true);
        const resp = await getRequest(`Video/Delete/${videoId}`);
        setVideoData(resp.data);
        setLoading(false);
        getVideoes();
    };

    const handleSave = async () => {
        setLoading(true);

        const postObj = {
            videoId: 0,
            videoFileTitle: videoData.videoFileTitle,
            videoFilePath: videoData.videoFilePath,
            isActive: videoData.isActive,
        };

        if (videoData.videoId) {
            postObj.videoId = videoData.videoId;
        }

        const response = await postRequest(`Video/PostVideo`, postObj);

        if (response.status === 200) {
            setEditing(false);
            setVideoData(emptyData);
            setErrors(emptyData);
            getVideoes();
            setConfirmModalOpen(false);
        }

        setLoading(false);
    };

    const handleEdit = (data) => {
        setConfirmModalOpen(true);
        setEditing(true);
        setVideoData({
            videoId: data.videoId,
            videoFileTitle: data.videoFileTitle,
            videoFilePath: data.videoFilePath,
            isActive: data.isActive,
        });
    };

    const handleDelete = (e, vdo) => {
        e.preventDefault();
        setDeleteConfirmModalOpen(true);
        setConfirmModalData(vdo);
    };

    const getVideoes = async () => {
        setLoading(true);
        const resp = await getRequest('Video/GetVideo');
        setUsers(resp.data);
        setLoading(false);
    };

    const handleAddUser = () => {
        setConfirmModalOpen(true);
    };

    const handleCheckboxChange = () => {
        setVideoData({
            ...videoData,
            isActive: !videoData.isActive
        });
    };

    const handleCloseDeleteConfirmationModal = () => {
        setConfirmModalData({});
        setDeleteConfirmModalOpen(false);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setVideoData(emptyData);
        setErrors(emptyData);
        setEditing(false);
    };

    const handleDeleteConfirmModalYes = async () => {
        await removeVideo(confirmModalData.videoId);
        setDeleteConfirmModalOpen(false);
    };

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <MainA active="expense-head-admin" className="b-video-wrapper">
            <Loader loading={loading} />
            <Modal isOpen={deleteConfirmModalOpen} closeModal={handleCloseDeleteConfirmationModal} className="delete-popup" >
                <div className='wrapper'>
                    <h4>Are you sure you want to remove the video?</h4>
                    <div className='v-modal-button-wrapper'>
                        <Button onClick={handleDeleteConfirmModalYes} >Yes remove it</Button>
                        <Button onClick={handleCloseDeleteConfirmationModal} type="primary">Cancel</Button>
                    </div>
                </div>
            </Modal>
            {confirmModalOpen && (
                <Modal isOpen={confirmModalOpen} closeModal={handleCloseConfirmationModal} className="add-edit-wrapper" >
                    <div>
                        <div className='flex'>
                            <h4 className='heading-bottom-padding'>{editing ? 'Edit Video' : 'Add New Video'}</h4>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <label htmlFor="videoFileTitle">Video Title:</label>
                                <Input type="text" id="videoFileTitle" value={videoData.videoFileTitle} onChange={(e) => handleChange(e.target.value, 'videoFileTitle')} required />
                                {errors.videoFileTitle && (
                                    <ErrorMsg className="v-errorMsg">{errors.videoFileTitle}</ErrorMsg>
                                )}
                            </div>
                            <div className='row'>
                                <label htmlFor="videoFilePath">Youtube Link:</label>
                                <Input type="text" id="videoFilePath" value={videoData.videoFilePath} onChange={(e) => handleChange(e.target.value, 'videoFilePath')} required />
                                {errors.videoFilePath && (
                                    <ErrorMsg className="v-errorMsg">{errors.videoFilePath}</ErrorMsg>
                                )}
                            </div>
                            <div className='checkbox-wrapper'>
                                <label>Active</label>
                                <ToggleSwitch checked={videoData.isActive} onChange={handleCheckboxChange} />
                            </div>

                            <Button className="add-btn" type="submit">Submit</Button>
                        </form>
                    </div>
                </Modal>
            )}

            <div className="video-wrapper">

                <div className='heading-wrapper'>
                    <div className='side'>
                        <p>Show</p>
                        <CustomDropdown
                            options={[
                                { label: '10', value: 10 },
                                { label: '20', value: 20 },
                                { label: '50', value: 50 },
                            ]}
                            onSelect={(option) => setRowsPerPage(option.value)}
                            value={{ label: rowsPerPage.toString(), value: rowsPerPage }}
                        />
                        <Button onClick={handleAddUser}><img src={plus} alt='plus icon' /> Add New Video</Button>
                    </div>
                </div>


                <Table pagination={true} pageSize={rowsPerPage}>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Path</th>
                            <th>Active</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.length > 0 ? (
                            users.map((vdo) => (
                                <tr key={vdo.videoId}>
                                    <td>{vdo.videoFileTitle}</td>
                                    <td>
                                        {isValidUrl(vdo.videoFilePath) ? (
                                            <Anchor to={vdo.videoFilePath} target="_blank" rel="noopener noreferrer">
                                                {vdo.videoFilePath}
                                            </Anchor>
                                        ) : (
                                            <>

                                                <span className="invalid-url"> {vdo.videoFilePath} </span>
                                                <span className="invalid-url"> (Invalid Link)</span>

                                            </>
                                        )}
                                    </td>
                                    <td>{vdo.isActive ? "Active" : "Inactive"}</td>
                                    <td>
                                        <img
                                            src={edit}
                                            alt="Edit"
                                            className="edit-icon"
                                            onClick={() => handleEdit(vdo)}
                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                        />
                                        <img
                                            src={remove}
                                            alt="Delete"
                                            className={`delete-icon ${vdo.isActive ? 'check-green' : 'xmark-red'}`}
                                            onClick={(e) => handleDelete(e, vdo)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5}><h5 className="centered-text">No Video Found</h5></td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </div>
        </MainA>
    );
};

export default VideoGallery;
