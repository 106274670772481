import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './styles.scss';
import Input from '../../components/atoms/Input';
import Button from '../../components/atoms/Button';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import MainA from '../../layout/Main Admin';
import { getCurrentUser } from '../../utils/utils';
import { postRequest } from '../../utils/axios';
import Loader from '../../components/atoms/Loader';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const ResetPassword = () => {
  const navigate = useNavigate();

  const currentUser = getCurrentUser();
  const userName = currentUser?.userName;
  const isFirstLogin = currentUser?.isFirstLogin;

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    response: '',
  })

  const handleChangePassword = async () => {
    setLoading(true);

    const param = {
      Username: userName,
      OldPassword: oldPassword,
      NewPassword: newPassword

    }
    const resp = await postRequest(`user/changepassword`, param);
    if (resp.status === 200 && resp.data) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentFinancialYear');
      localStorage.removeItem('currentUserImage');
      NotificationManager.success('Success message', 'Password changed Successfully');
      navigate('/');
    } else {
      const errorObj = {
        response: resp?.response?.data?.message || '',
      }
      setErrors({ ...errorObj })
    }
    setLoading(false);

  }

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    setOldPassword(value);
  }

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
  }

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
  }

  const handleValidation = () => {
    const errorObj = {};
    if (oldPassword === '') {
      errorObj.oldPassword = 'Please enter Current Password';
    } else {
      errorObj.oldPassword = '';
    }

    if (newPassword === '') {
      errorObj.newPassword = 'Please enter New Password';
    } else if (newPassword.length < 4) {
      errorObj.newPassword = 'Password must have 4 characters'
    } else {
      errorObj.newPassword = ''
    }

    if (confirmPassword === '') {
      errorObj.confirmPassword = 'Please enter Confirm Password';
    } else if (confirmPassword !== newPassword) {
      errorObj.confirmPassword = 'Passwords do not match';
    } else {
      errorObj.confirmPassword = '';
    }

    errorObj.response = '';

    const validated = oldPassword && newPassword.length >= 4 && (newPassword === confirmPassword);


    setErrors({ ...errors, ...errorObj })

    return validated;
  }

  const handleSubmit = () => {
    const validated = handleValidation();

    if (validated) {
      handleChangePassword();
    }
  }

  if (!isFirstLogin) {
    return (
      <MainA active="reset-password" className="reset-password-wrapper">
        <Loader loading={loading} />
        <div className='form-wrapper'>

          <div className='data'>

            <div className='row'>
              <label htmlFor="first-name">Current Password</label>
              <div className="input-container">
                <Input className="text-box-new" type="password" value={oldPassword} onChange={(e) => { handleOldPasswordChange(e) }} placeholder='Current Password' />
                {errors.oldPassword && (
                  <ErrorMsg>{errors.oldPassword}</ErrorMsg>
                )}
              </div>
            </div>
            <div className='row'>
              <label for="first-name">New Password</label>
              <div className="input-container">
                <Input className="text-box-new" type='password' value={newPassword} onChange={(e) => { handleNewPasswordChange(e) }} placeholder='New Password' />
                {errors.newPassword && (
                  <ErrorMsg>{errors.newPassword}</ErrorMsg>
                )}
              </div>
            </div>
            <div className='row'>
              <label for="first-name">Confirm Password</label>
              <div className="input-container">
                <>
                  <Input className="text-box-new" type='password' value={confirmPassword} onChange={(e) => { handleConfirmPasswordChange(e) }} placeholder='Confirm Password' />
                  {errors.confirmPassword && (
                    <ErrorMsg>{errors.confirmPassword}</ErrorMsg>
                  )}
                  {errors.response && (
                    <ErrorMsg>{errors.response}</ErrorMsg>
                  )}
                </>
              </div>
            </div>


            <div className='row'>
              <Button className="btn-info" onClick={() => handleSubmit()}>Save</Button>
            </div>
          </div>
        </div>
      </MainA>
    );
  }

  // return (
  //   <div className="reset-password-wrapper design-2">

  //     {/* <Image src={bgIMage} className="bg-image" alt="Background image " /> */}
  //     <div className='form-wrapper'>
  //       {/* <h1>RESET PASSWORD</h1> */}
  //       <div className='row'>
  //         <label htmlFor="first-name">Current Password</label>
  //         <div className="input-container">
  //           <>
  //             <Input className="text-box-new" type="password" value={oldPassword} onChange={(e) => { handleOldPasswordChange(e) }} placeholder='Current Password' />
  //             {errors.oldPassword && (
  //               <ErrorMsg>{errors.oldPassword}</ErrorMsg>
  //             )}
  //           </>
  //         </div>
  //       </div>

  //       <div className='row'>
  //         <label for="first-name">New Password</label>
  //         <div className="input-container">
  //           <>
  //             <Input className="text-box-new" type='password' value={newPassword} onChange={(e) => { handleNewPasswordChange(e) }} placeholder='New Password' />
  //             {errors.newPassword && (
  //               <ErrorMsg>{errors.newPassword}</ErrorMsg>
  //             )}
  //           </>
  //         </div>
  //       </div>
  //       <div className='row'>
  //         <label for="first-name">Confirm Password</label>
  //         <div className="input-container">
  //           <>
  //             <Input className="text-box-new" type='password' value={confirmPassword} onChange={(e) => { handleConfirmPasswordChange(e) }} placeholder='Confirm Password' />
  //             {errors.confirmPassword && (
  //               <ErrorMsg>{errors.confirmPassword}</ErrorMsg>
  //             )}
  //           </>
  //         </div>
  //       </div>
  //       <div className='row'>
  //         <Button className="btn-info" type="primary" onClick={() => handleSubmit()}>Save</Button>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default ResetPassword;