import React, { useEffect, useCallback } from 'react';
import './styles.scss';

const Dropdown = ({ label, children, isOpen, toggleDropdown }) => {
    
    const handleOutsideClick = useCallback((event) => {
        if (!event.target.closest('.dropdown')) {
            toggleDropdown(false); 
        }
    }, [toggleDropdown]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen, handleOutsideClick]);

    return (
        <div className={`dropdown ${isOpen ? 'is-open' : ''}`}>
            <button onClick={() => toggleDropdown(!isOpen)} className="dropdown-toggle">
                {label}
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    {children}
                </div>
            )}
        </div>
    );
};

export default React.memo(Dropdown);
