import React, { Fragment } from 'react';
import { PropTypes } from "prop-types";
import './styles.scss';

const Modal = ({ className, id, children, isOpen, closeModal, ...rest }) => {

    const handleClose = () => {
        closeModal();
    }

    return (
        <Fragment>
            <div onClick={() => handleClose()} className={`v-modal-overlay ${isOpen ? '' : 'hide'}`}></div>
            <div className={`v-modal ${isOpen ? '' : 'hide'} ${className}`} {...rest}>
                <button className="v-modal-close" onClick={handleClose}>
                    &#x2715; 
                </button>
                {children}
            </div>
        </Fragment>
    );
}

Modal.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    children: PropTypes.node,
}

Modal.defaultProps = {
    className: '',
    id: '',
    isOpen: false,
    children: <></>,
}

export default Modal;
