import React, { useEffect, useRef, useState } from 'react';
import Image from '../../atoms/Image';
import ErrorMsg from '../../atoms/ErrorMsg';
import './styles.scss';
import uploadIcon from "../../../assets/icons/upload.svg";

const Upload = ({ className, value, allowedFiles, onChange, resetFile, ...rest }) => {

    const fileInputRef = useRef();

    const [fileName, setFileName] = useState('');
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        fileInputRef.current.value = '';
        setFileName('');
    }, [resetFile])

    const handelChange = () => {
        const file = fileInputRef.current.files.length > 0 && fileInputRef.current.files[0];

        if (!file) return;

        const fileName = file.name || '';
        const extension = fileName.split('.')[1];
        const allowed = allowedFiles.indexOf(extension) > -1;
        if(allowed) {
            setFileError('');
            const fileName = file.name || '';
            setFileName(fileName);
            onChange(file);
        } else {
            setFileError(`${extension} file type is not allowed`)
        }
    }

    return (
        <div className='upload-area' {...rest}>
            {!fileName && (
                <div className='image'>
                    <Image className="svg-upload" src={uploadIcon} alt="Upload Icon" />
                </div>
            )}
            {fileName && (
                <div className='file-name-text'>File Name: {fileName}</div>
            )}
            {!fileName && (
                <div className='drop-area'>
                    <div className='drop-text'>
                        Drop file here or click to upload
                    </div>
                    {allowedFiles && (
                        <div className='requirement-text'>
                            Only {allowedFiles} file allowed
                        </div>
                    )}
                </div>
            )}
            {fileError && (
                <ErrorMsg>{fileError}</ErrorMsg>
            )}

            <input
                className={`form-control upload-input ${className}`}
                type="file"
                value={value}
                onChange={handelChange}
                ref={fileInputRef}
                accept={allowedFiles}
            />
        </div>


    );
}

export default Upload;