import React from 'react';
import { PropTypes } from "prop-types";
import './styles.scss';

const Loader = ({className, loading, ...rest}) => {
  return (
    <div
        className={`v-loader ${className} ${loading ? '' : 'hide'}`}
        {...rest}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2" r=".3" cx="95" cy="65">
          <animate attributeName="cy" calcMode="spline" dur="2" values="90;100;90;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
        </circle>
        <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2" r=".3" cx="100" cy="65">
          <animate attributeName="cy" calcMode="spline" dur="2" values="90;100;90;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
        </circle>
        <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="2" r=".3" cx="105" cy="65">
          <animate attributeName="cy" calcMode="spline" dur="2" values="90;100;90;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
        </circle>
      </svg>
    </div>
  );
}

Loader.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
}

Loader.defaultProps = {
    className: '',
    loading: false
}

export default Loader;