import React from 'react';
import Admin from './index';
import Blogs from './blogs';
import Category from '.././admin/category';
import Pages from './pages';
import SMTP from '.././admin/smtp';
import ResetPassword from '.././admin/reset-password';
import AddIns from '.././admin/add-ins';
import ModuleUsage from '.././admin/module-usage';
import ActivationHistory from '.././admin/activation-history';
import Components from '.././admin/components';
import MetaTags from '.././admin/meta-tags';
import ExistingGptMetaTags from '.././admin/existing-gpt-meta-tags';
import ComponentPage from './component-page';
import Queries from '.././admin/queries';
import Gallery from '.././admin/gallery';
import VideoGallery from '.././admin/video-gallery'; 
import EventVideoGallery from '.././admin/event-video-gallery'; 
import CategoryGallery from '.././admin/category-gallery';
import Document from '.././admin/document';
import CategoryPages from ".././admin/categoryPages";
import Users from ".././admin/users";
import Clients from '../admin/clients';
import ClientData from './client-data';
import ClientDeletedImages from './client-deleted-images';
import { Navigate } from 'react-router-dom';


const Routes = [
  {
    path: "*",
    element: <Navigate to="/" replace />
  },
  {
    path: "/",
    element: <Admin />,
  },
  {
    path: "/",
    element: <Admin />,
  },
  {
    path: "/clients",
    element: <Clients/>
  },
  {
    path: "/client-data",
    element: <ClientData/>
  },
  {
    path: "/client-data/:existingClientId",
    element: <ClientData/>
  },
  {
    path: "/client-deleted-images/:galleryClientId",
    element: <ClientDeletedImages/>
  },
  {
    path: "/:categoryName/:categoryId",
    element: <CategoryPages />,
  },
  {
    path: "/components",
    element: <Components />,
  },
  {
    path: "/queries",
    element: <Queries />,
  },
  {
    path: "/meta-tags/:category/:categoryId/:pageUrl/:page",
    element: <MetaTags />,
  },
  {
    path: "/existing-gpt-meta-tags/:page",
    element: <ExistingGptMetaTags />,
  },
  {
    path: "/component-page/:pageName/:page",
    element: <ComponentPage />,
  },
  {
    path: "/pages",
    element: <Pages />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/category",
    element: <Category />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/video-gallery",
    element: <VideoGallery />,
  },
  {
    path: "/:categoryName/category-gallery/:page",
    element: <CategoryGallery />,
  },
  {
    path: "/:categoryName/video-gallery/:page",
    element: <EventVideoGallery />,
  },
  {
    path: "/document",
    element: <Document />,
  },
  {
    path: "/smtp",
    element: <SMTP />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/users/:clientId",
    element: <Users/>,
  },
  {
    path: "/module-usage",
    element: <ModuleUsage />,
  },
  {
    path: "/activation-history",
    element: <ActivationHistory />,
  },
  {
    path: "/addIns",
    element: <AddIns />,
  },
];


export default Routes;