
import React, { useEffect, useState, Fragment } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import { getDynamicHostName, getDateFormat } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

import Table from '../../components/atoms/Table'
import MainA from '../../layout/Main Admin';
import Dropdown from '../../components/atoms/Dropdown';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Loader from '../../components/atoms/Loader';
import CustomEditor from '../../components/atoms/CustomEditor';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Modal from '../../components/atoms/Modal';
import Anchor from '../../components/atoms/Anchor';
import CustomDropdown from '../../components/atoms/CustomDropdown';
import ToggleSwitch from '../../components/atoms/ToggleSwitch';

import plus from '../../assets/icons/plus.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';
import edit from '../../assets/icons/edit.svg';
import copy from '../../assets/icons/copy.svg';
import video from '../../assets/icons/video-black.svg';
import photos from '../../assets/icons/camera.svg';
import tag from '../../assets/icons/tag.svg';

import './styles.scss';

const CategoryPages = () => {

  const navigate = useNavigate();
  const { categoryName, categoryId, pageName } = useParams();
  const dynamicHostName = getDynamicHostName();
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [openDropdown, setOpenDropdown] = useState(null);

  const emptyData = {
    pageTitle: '',
    pageName: '',
    publishDate: '',
    shortDescription: '',
    pageDescription: '',
    isActive: false,
    tags: '',
    pageUrl: '',
    categoryId: '',
    featureImageId: '',
    userId: null
  };
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('admin');
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(emptyData);
  const [categoryPagesData, setCategoryPagesData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryActive, setCategoryActive] = useState(true);
  const [users, setUsers] = useState([]);

  const [errors, setErrors] = useState({
    pageTitle: '',
    pageName: '',
    publishDate: '',
    shortDescription: '',
    pageDescription: '',
    isActive: false,
    tags: '',
    pageUrl: '',
    categoryId: '',
    featureImageId: '',
    userId: ''

  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(emptyData);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [duplicateModalData, setDuplicateModalData] = useState(emptyData);

  useEffect(() => {
    getUsers();
    getData();
    getCategory();
    setEditing(false);
    setData(emptyData);
    getCategoryTypes(categoryId);
    getGallery();
  }, [categoryId, pageName, categoryName]);

  const getData = async () => {
    setLoading(true);
    const resp = await getRequest(`Page/GetPagesByCategoryType/${categoryId}`);
    setCategoryPagesData(resp.data.reverse());

    setLoading(false);
  }
  const getUsers = async () => {
    setLoading(true);
    const resp = await getRequest(`user/GetUsers`);
    setUsers(resp.data.reverse());
    setLoading(false);
  }
  const getGallery = async () => {
    setLoading(true);
    const resp = await getRequest(`Gallery/GetGallery`);
    setGalleryData(resp.data);
    setLoading(false);
  };
  const getCategory = async () => {
    const resp = await getRequest(`Category/GetCategoriesByTypeId/${categoryId}`);
    setCategoryData(resp.data);
  }

  const getCategoryTypes = async (categoryId) => {
    setLoading(true);
    const resp = await getRequest(`CategoryType/GetCategoryTypes`);
    const data = resp.data;
    let isActive = true;
    data.forEach(d => {
      if (d.categoryTypeId === Number(categoryId)) {
        isActive = d.isActive;
      }
    })
    setCategoryActive(isActive)
    setLoading(false);
  }

  const handleAdd = () => {
    setEditing(true);
  }
  const handleDuplicateEntity = (data) => {

    setDuplicateModalOpen(true);
    setDuplicateModalData(data);
  };
  const handleDuplicate = async (pageId) => {
    setLoading(true);
    const resp = await getRequest(`Page/Duplicate/${pageId}`);
    setCategoryData(resp.data);
    await getData();
    setLoading(false);
  };

  const handleGallery = (data) => {
    sessionStorage.setItem('pageName', data.pageName);
    navigate(`/${categoryName}/category-gallery/${data.pageId}`);
  };

  const handleVideoGallery = (data) => {
    sessionStorage.setItem('pageName', data.pageName);
    navigate(`/${categoryName}/video-gallery/${data.pageId}`);
  };

  const handleEdit = (data) => {
    setEditing(true);
    const selectedUser = users.find((u) => {
      return (u.userId === data.userId)
    })
    setData({
      pageTitle: data.pageTitle,
      pageName: data.pageName,
      publishDate: getDateFormat(data.publishDate, 'YYYY-MM-DD'),
      shortDescription: data.shortDescription,
      pageDescription: data.pageDescription,
      isActive: data.isActive,
      tags: data.tags,
      pageUrl: data.pageUrl,
      pageId: data.pageId,
      categoryId: {
        label: data.category?.categoryName,
        value: data.category?.categoryId,
      },
      featureImageId: {
        label: data.featureImage?.galleryFileTitle,
        value: data.featureImage?.galleryId,
      },
      userId: {
        label: `${selectedUser?.userFirstName} ${selectedUser?.userLastName}`,
        value: data?.userId,
      },
    })
  }

  const handleValidation = () => {
    const errorObj = {};
    let validated = false;
    if (data.pageTitle === '') {
      errorObj.pageTitle = 'Please enter Page Title';
      validated = true;
    } else {
      errorObj.pageTitle = '';
    }

    if (data.pageName === '') {
      errorObj.pageName = 'Please enter Page Name';
      validated = true;
    } else {
      errorObj.pageName = '';
    }

    if (data.pageUrl === '') {
      errorObj.pageUrl = 'Please enter Page Url';
      validated = true;
    } else {
      errorObj.pageUrl = '';
    }

    if (data.categoryId?.value === '') {
      errorObj.categoryId = 'Please enter Category Id';
      validated = true;
    } else {
      errorObj.categoryId = '';
    }

    if (data.shortDescription === '') {
      errorObj.shortDescription = 'Please enter Short Description';
      validated = true;
    } else {
      errorObj.shortDescription = '';
    }

    if (data.pageDescription === '') {
      errorObj.pageDescription = 'Please enter Page Description';
      validated = true;
    } else {
      errorObj.pageDescription = '';
    }

    if (data.displayOrder === '') {
      errorObj.displayOrder = 'Please enter Display Order';
      validated = true;
    } else {
      errorObj.displayOrder = '';
    }


    if (data.userId.value === '') {
      errorObj.userId = 'Please select a blogger';
    } else {
      errorObj.userId = '';
    }


    setErrors({ ...errors, ...errorObj })

    return !validated;
  }

  const handleBack = () => {
    setEditing(false);
    setData(emptyData);
    setErrors({});
  }

  const handleChange = (value, type) => {
    setData({
      ...data,
      [type]: value
    })
  }

  const handleCheckboxChange = () => {
    setData({
      ...data,
      isActive: !data.isActive
    })
  }

  const handleStatusClick = (wnd) => {
    setConfirmModalOpen(true);
    setConfirmModalData(wnd);
  };


  const handleSubmit = () => {

    const validated = handleValidation();
    if (validated) {
      handleSave();
    }
  }
  const handleReset = (e) => {
    e.preventDefault();
    setData(emptyData);
    setErrors({});
  }

  const handleSave = async (existingData = {}) => {
    setLoading(true);

    const postObj = {
      pageTitle: existingData.pageTitle || data.pageTitle,
      pageName: existingData.pageName || data.pageName,
      publishDate: existingData.publishDate === '' || data.publishDate === '' ? null : existingData.publishDate || data.publishDate,
      shortDescription: existingData.shortDescription || data.shortDescription,
      pageDescription: existingData.pageDescription || data.pageDescription,
      isActive: existingData.isActive !== undefined ? existingData.isActive : data.isActive,
      tags: existingData.tags || data.tags,
      pageUrl: existingData.pageUrl || data.pageUrl,
      pageId: existingData.pageId || data.pageId,
      categoryId: existingData.categoryId?.value || data.categoryId?.value,
      featureImageId: existingData.featureImageId?.value === '' || data.featureImageId?.value === '' ? null : existingData.featureImageId?.value || data.featureImageId?.value,
      userId: existingData.userId?.value === '' || data.userId?.value === '' ? null : existingData.userId?.value || data.userId?.value,
    };

    try {
      const response = await postRequest(`Page/PostPage`, postObj);

      if (response.status === 200) {
        setEditing(false);
        getData();
        NotificationManager.success('Success message', 'Data is uploaded Successfully');
      } else {
        NotificationManager.error('Error message', 'Data could not be uploaded');
      }
    } catch (error) {
      NotificationManager.error('Error message', 'An error occurred while saving the data');
    } finally {
      setLoading(false);
    }
  };


  const handleCloseCofirmationModal = () => {
    setConfirmModalData(emptyData);
    setConfirmModalOpen(false);
  }

  const handleConfirmModalYes = () => {
    if (confirmModalData && confirmModalData.pageId) {
      confirmModalData.isActive = !confirmModalData.isActive;
      handleSave(confirmModalData);
      setConfirmModalOpen(false);
    } else {
      NotificationManager.error("Error", "Unable to change status due to missing data");
    }
  };

  const handleCloseDuplicateModal = () => {
    setDuplicateModalData(emptyData);
    setDuplicateModalOpen(false);
  }

  const handleDuplicateModalYes = async () => {
    await handleDuplicate(duplicateModalData.pageId);
    setDuplicateModalOpen(false);
  }

  const uniqueCategories = Array.from(new Set(categoryPagesData.map(wnd => wnd.category.categoryName)));

  useEffect(() => {
    // Filter based on search term
    const filtered = categoryPagesData.filter(wnd =>
      wnd.pageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wnd.category.categoryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wnd.pageTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wnd.pageUrl.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Further filter based on selected category
    const categoryFiltered = selectedCategory
      ? filtered.filter(wnd => wnd.category.categoryName === selectedCategory)
      : filtered;

    // Further filter based on selected status
    const statusFiltered = statusFilter === 'all'
      ? categoryFiltered
      : categoryFiltered.filter(wnd => statusFilter === 'active' ? wnd.isActive : !wnd.isActive);

    // Update the filtered data state
    setFilteredData(statusFiltered);
  }, [categoryPagesData, searchTerm, selectedCategory, statusFilter]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleDropdown = (wndId) => {
    if (openDropdown === wndId) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(wndId);
    }
  };

  return (
    <MainA className="b-all-category-page">
      <Loader loading={loading} />

      <Modal isOpen={confirmModalOpen} closeModal={handleCloseCofirmationModal} className="modal-1">
        <h4>Are you sure?</h4>
        <p>you want to change the status?</p>
        <div className='v-modal-button-wrapper'>
          <Button onClick={handleConfirmModalYes} >Yes, change status</Button>
          <Button onClick={handleCloseCofirmationModal} type="primary" >No</Button>
        </div>
      </Modal>

      <Modal isOpen={duplicateModalOpen} closeModal={handleCloseDuplicateModal} className="modal-2">
        <div className='circle'>!</div>
        <div>
          <h4>Are you sure? </h4>
          <p>You want to make dupicate of entity</p>
          <div className='v-modal-button-wrapper'>
            <Button onClick={handleDuplicateModalYes}>Yes, Duplicate entity</Button>
            <Button onClick={handleCloseDuplicateModal} type="primary">Cancel</Button>
          </div>
        </div>
      </Modal>

      {userType === 'admin' ?
        <div className='all-category-wrapper'>
          <div className='heading-wrapper'>
            <div className='left-side'>
              {!editing && (
                <>
                  <p>Show</p>
                  <CustomDropdown
                    options={[
                      { label: '10', value: 10 },
                      { label: '20', value: 20 },
                      { label: '50', value: 50 },
                    ]}
                    onSelect={(option) => setRowsPerPage(option.value)}
                    value={{ label: rowsPerPage.toString(), value: rowsPerPage }}
                  />
                </>
              )}

              {categoryActive && editing && (
                <Button onClick={handleBack} data-testid="data-back">
                  <img src={leftArrow} alt='left arrow' /> Back
                </Button>
              )}

              {categoryActive && !editing && (
                <Button onClick={handleAdd} className="add-btn" data-testid="data-add">
                  <img src={plus} alt='plus icon' /> Add
                </Button>
              )}
            </div>

            {!editing && (
              <div className='right-side'>
                <Input
                  type="text"
                  placeholder={`Search ${categoryName}`}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                <CustomDropdown
                  options={[
                    { label: 'All Categories', value: '' },
                    ...uniqueCategories
                      .map((category) => ({
                        label: category,
                        value: category,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))
                  ]}
                  onSelect={(option) => setSelectedCategory(option.value)}
                  value={{ label: selectedCategory || 'Categories', value: selectedCategory }}
                  className={pageName === 'blogs' ? 'dropdown-blogs' : ''}
                />
                {categoryName !== 'pages' && (
                  <CustomDropdown
                    options={[
                      { label: 'Status', value: 'all' },
                      { label: 'Active', value: 'active' },
                      { label: 'Inactive', value: 'inactive' },
                    ]}
                    className="status-width"
                    onSelect={(option) => setStatusFilter(option.value)}
                    value={{ label: statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1), value: statusFilter }}
                  />
                )}
              </div>
            )}
          </div>

          {editing ? (
            <div className='edit-wrapper'>

              <div className='dual-input'>
                <div className='row'>
                  <label>{categoryName} Name</label>
                  <Input type="text" value={data.pageName} onChange={(e) => handleChange(e.target.value, 'pageName')} placeholder="Enter Page Name " />
                  {errors.pageName && (
                    <ErrorMsg className="v-errorMsg">{errors.pageName}</ErrorMsg>
                  )}
                </div>
                <div className='row'>
                  <label>{categoryName} Title</label>
                  <Input type="text" value={data.pageTitle} onChange={(e) => handleChange(e.target.value, 'pageTitle')} placeholder="Enter Page Title " />
                  {errors.pageTitle && (
                    <ErrorMsg className="v-errorMsg">{errors.pageTitle}</ErrorMsg>
                  )}
                </div>
              </div>

              <div className='dual-input'>
                <div className="form-group">
                  <label htmlFor="CategoryName">Category Name</label>
                  <CustomDropdown
                    id="CategoryName"
                    className='custom-dropdown-variant'
                    value={data.categoryId}
                    onSelect={(option) => handleChange(option, 'categoryId')}
                    options={categoryData.length && categoryData.map((wnd) => ({
                      value: wnd.categoryId,
                      label: wnd.categoryName,
                    }))}
                    placeholder="Select Category"
                  />
                  {errors.categoryId && (
                    <ErrorMsg className="v-errorMsg">{errors.categoryId}</ErrorMsg>
                  )}

                </div>

                {(categoryName === "team" && (

                  <div className="form-group">
                    <label htmlFor="FeatureImage">Feature Image</label>
                    <CustomDropdown
                      id="FeatureImage"
                      className='custom-dropdown-variant'
                      value={data.featureImageId}
                      onSelect={(option) => handleChange(option, 'featureImageId')}
                      options={galleryData.map((wnd) => ({
                        value: wnd.galleryId,
                        label: wnd.galleryFileTitle,
                      }))}
                      placeholder="Select Feature Image"
                    />
                  </div>
                ))}
              </div>


              <div className='dual-input'>
                {(categoryName === "blogs" || categoryName === "events") && categoryName !== "pages" && (
                  <>
                    <div className='row'>
                      <label>Tags</label>
                      <Input
                        type="text"
                        value={data.tags}
                        onChange={(e) => handleChange(e.target.value, 'tags')}
                        placeholder="Enter Redirect tags "
                      />
                    </div>
                  </>
                )}
                <div className="column">
                  <label>Redirect URL</label>
                  <Input
                    type="text"
                    value={data.pageUrl}
                    onChange={(e) => handleChange(e.target.value, 'pageUrl')}
                    placeholder="Enter Redirect URL "
                  />
                  {errors.pageUrl && (
                    <ErrorMsg className="v-errorMsg">{errors.pageUrl}</ErrorMsg>
                  )}
                </div>

              </div>

              {(categoryName === "blogs" || categoryName === "events") && categoryName !== "pages" && (
                <div className='dual-input'>

                  <div className="column">
                    <label>Publish Date</label>
                    <Input
                      type="date"
                      value={data.publishDate}
                      onChange={(e) => handleChange(e.target.value, 'publishDate')}
                      placeholder="Enter Page Publish Date "
                    />
                  </div>

                  <div className='row'>
                    <label htmlFor="Blogger">Blogger</label>
                    <CustomDropdown
                      className='custom-dropdown-variant'
                      id="Blogger"
                      value={data.userId}
                      onSelect={(option) => handleChange(option, 'userId')}
                      options={users.map((wnd) => ({
                        value: wnd.userId,
                        label: `${wnd.userFirstName} ${wnd.userLastName}`,
                      }))}
                      placeholder="Select Blogger"
                    />

                  </div>

                </div>
              )}

              <div className='row'>
                <label>Short Description</label>
                <Input type="textarea" value={data.shortDescription} onChange={(e) => handleChange(e.target.value, 'shortDescription')} placeholder="Enter Short Description" />
                {errors.shortDescription && (
                  <ErrorMsg className="v-errorMsg">{errors.shortDescription}</ErrorMsg>
                )}
              </div>
              <div className='row' id='Description'  >
                <label>{categoryName} Description</label>
                <CustomEditor
                  id='Description'
                  data={data.pageDescription}
                  onChange={(event, editor) => {
                    const editorData = editor.getData();
                    handleChange(editorData, 'pageDescription');
                  }}
                />
                {errors.pageDescription && (
                  <ErrorMsg className="v-errorMsg">{errors.pageDescription}</ErrorMsg>
                )}
              </div>

              <div className='clickables'>
                <div className='row'>
                  <label>Active</label>
                  <ToggleSwitch
                    checked={data.isActive}
                    onChange={handleCheckboxChange}
                  />
                </div>


                <div className='buttons'>
                  <Button onClick={handleReset} id='Reset' >Reset</Button>
                  <Button onClick={handleSubmit} id='Save' >Save</Button>
                  <Button onClick={handleBack} id='Cancel' type='primary'>Cancel</Button>
                </div>
              </div>


            </div>
          ) : (
            <div className='table-wrapper'>

              <Table
                pagination={filteredData.length > rowsPerPage}
                pageSize={rowsPerPage}
                selectedPage={currentPage}
                onPageChange={setCurrentPage}
                categoryName={categoryName}
              >
                <thead>
                  <tr>
                    <th>{categoryName} Name</th>
                    <th>{categoryName} Title</th>
                    {categoryName === 'blogs' && <th>Blogger Name</th>}
                    <th>{categoryName} URL</th>
                    <th>Category</th>
                    {categoryActive && (
                      <Fragment>
                        <th>Status</th>
                        <th>Action</th>
                      </Fragment>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((wnd) => (
                      <tr key={wnd.pageId}>
                        <td>{wnd.pageName}</td>

                        <td>{wnd.pageTitle}</td>
                        {categoryName === 'blogs' && (
                          <td>{wnd.user?.userFirstName} {wnd.user?.userLastName}</td>
                        )}
                        <td><Anchor to={`${dynamicHostName}${categoryName}/${wnd.pageUrl}`}>{wnd.pageUrl}</Anchor></td>
                        <td>{wnd.category.categoryName}</td>
                        {categoryActive && (
                          <Fragment>
                            <td>
                              <p
                                onClick={() => handleStatusClick(wnd)}
                                className={`status ${wnd.isActive ? 'active' : 'inactive'}`}
                              >
                                {wnd.isActive ? 'Active' : 'Inactive'}
                              </p>
                            </td>
                            <td>
                              {/* Edit button */}
                              <Anchor onClick={() => handleEdit(wnd)} type="primary" data-testid="edit-button">
                                <img src={edit} alt="Edit" />
                              </Anchor>

                              {/* Duplicate button outside the dropdown for "events" category */}
                              {categoryName === "events" && (
                                <Anchor onClick={() => handleDuplicateEntity(wnd)} type="primary" data-testid="duplicate-button">
                                  <img src={copy} alt="Duplicate" />
                                </Anchor>
                              )}

                              <Dropdown
                                isOpen={openDropdown === wnd.pageId}
                                toggleDropdown={() => toggleDropdown(wnd.pageId)}
                              >
                                <>
                                  <Anchor to={`/meta-tags/${categoryName}/${categoryId}/${wnd.pageUrl}/${wnd.pageId}`} data-testid="meta-tags">
                                    <img src={tag} /> <p> MetaTags </p>
                                  </Anchor>

                                  {categoryName !== "events" && (
                                    <div onClick={() => handleDuplicateEntity(wnd)} id="Duplicate" data-testid="duplicate-button">
                                      <Anchor to="#" onClick={(e) => { e.preventDefault(); handleDuplicateEntity(wnd); }}>
                                        <img src={copy} /> Duplicate
                                      </Anchor>
                                    </div>
                                  )}


                                  {categoryName === "pages" && (
                                    <Anchor to={`/component-page/${wnd.pageName}/${wnd.pageId}`} className="edit-btn" data-testid="component-button">
                                      Components
                                    </Anchor>
                                  )}

                                  {categoryName === "events" && (
                                    <>
                                      <div onClick={() => handleGallery(wnd)}>
                                        <Anchor to="#" onClick={(e) => { e.preventDefault(); handleGallery(wnd); }}>
                                          <img src={photos} /> Photos
                                        </Anchor>
                                      </div>
                                      <div onClick={() => handleVideoGallery(wnd)}>
                                        <Anchor to="#" onClick={(e) => { e.preventDefault(); handleVideoGallery(wnd); }}>
                                          <img src={video} /> Videos
                                        </Anchor>
                                      </div>
                                    </>
                                  )}
                                </>
                              </Dropdown>


                            </td>
                          </Fragment>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}><h5 className="centered-text">No {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)} Found</h5></td>
                    </tr>
                  )}
                </tbody>
              </Table>

            </div>
          )}

        </div>
        : 'Page Not Found'

      }
    </MainA >
  );
}

export default CategoryPages;