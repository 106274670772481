import moment from "moment";

const getDate = (date) => {
    if(date) {
        return moment(date).format('MMM DD, YYYY')
    }
    return date
}
const getDateFormat = (date,format) => {
    if(date) {
        return moment(date).format(format)
    }
    return date
}
const getDateTime = (dateTime) => {
    if (dateTime) {
        return moment(dateTime).format('MMM DD, YYYY, hh:mm A');
    }
    return dateTime;
};

const getCurrentUser = () => {
    let currentUser = localStorage.getItem('currentUser');
    if(currentUser && currentUser !== "undefined") {
        currentUser = JSON.parse(currentUser);
    }
    return currentUser
}
const getToken = () => {
    let token = localStorage.getItem('token');

    if(token && token !== "undefined") {
        token = JSON.parse(token);
        return token.token
    }
    return ''
}
const getClientHomeImage = () => {
    let token = localStorage.getItem('token');

    if (token && token !== "undefined") {
        token = JSON.parse(token);
        if (token.clientHomeImage) {
            return token.clientHomeImage;
        }
    }
    return '';
}
const getDynamicHostName = () => {
    let token = localStorage.getItem('token');

    if (token && token !== "undefined") {
        token = JSON.parse(token);
        if (token.dynamicHostName) {
            return token.dynamicHostName;
        }
    }
    return '';
}

const getCurrentUserImage = () => {
    return localStorage.getItem("currentUserImage")
}

const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export { getDate,getDateTime,getDateFormat, getCurrentUser, getCurrentUserImage, getQueryParam, validateEmail, getToken, getClientHomeImage, getDynamicHostName };