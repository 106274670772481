
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import Table from '../../components/atoms/Table'
import './styles.scss';
import MainA from '../../layout/Main Admin';
import { getCurrentUser } from '../../utils/utils';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Loader from '../../components/atoms/Loader';
import CustomEditor from '../../components/atoms/CustomEditor';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Modal from '../../components/atoms/Modal';
import Anchor from '../../components/atoms/Anchor';

const WhatsNewPages = () => {
  const emptyData = {

    pageTitle: '',
    pageName: '',
    pageDescription: '',
    isActive: false,
    pageUrl: '',
    categoryId: ''
  };
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('');
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState(emptyData);
  const [userData, setUserData] = useState({});
  const [pagesData, setPagesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [errors, setErrors] = useState({
    pageTitle: '',
    pageName: '',
    pageDescription: '',
    isActive: false,
    pageUrl: '',
    categoryId: ''
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(emptyData);

  useEffect(() => {
    let user = getCurrentUser();
    const userTypeId = 4;

    if (userTypeId === 4) {
      setUserType('admin')
    }
    if (user) {
      setUserData({
        username: user?.pageName,
        pageId: user?.pageId,
      })
    }

    getData()
    getCategory()
  }, [])

  const getData = async () => {
    setLoading(true); 
    const resp = await getRequest(`Page/GetPagesByCategoryTypeName/pages`);
    setPagesData(resp.data);
    setLoading(false);
  }

  const getCategory = async () => {
    setLoading(true);
    const resp = await getRequest(`Category/GetCategoriesByTypeId/2`);
    setCategoryData(resp.data);
    console.log(resp.data);
    setLoading(false);
  }


  const handleAdd = () => {
    setEditing(true);
  }

  const handleEdit = (data) => {
    setEditing(true);
    setData({
      pageTitle: data.pageTitle,
      pageName: data.pageName,
      pageDescription: data.pageDescription,
      isActive: data.isActive,
      pageUrl: data.pageUrl,
      pageId: data.pageId,
      categoryId: data.categoryId,
    })
  }

  const handleValidation = () => {
    const errorObj = {};
    if (data.pageTitle === '') {
      errorObj.pageTitle = 'Please enter Page Title';
    } else {
      errorObj.pageTitle = '';
    }
    if (data.pageName === '') {
      errorObj.pageName = 'Please enter Page Name';
    } else {
      errorObj.pageName = '';
    }
    if (data.pageUrl === '') {
      errorObj.pageUrl = 'Please enter Redirect URL';
    } else {
      errorObj.pageUrl = '';
    }
    if (data.categoryId === '') {
      errorObj.categoryId = 'Please enter Category ID';
    } else {
      errorObj.categoryId = '';
    }
    if (data.pageDescription === '') {
      errorObj.pageDescription = 'Please enter Page Description';
    } else {
      errorObj.pageDescription = '';
    }
    if (data.displayOrder === '') {
      errorObj.displayOrder = 'Please enter Display Order';
    } else {
      errorObj.displayOrder = '';
    }


    setErrors({ ...errors, ...errorObj })

    const validated = data.pageTitle && data.pageName && data.pageUrl && data.categoryId && data.isActive && data.pageDescription;
    return validated;
  }

  const handleBack = () => {
    setEditing(false);
    setData(emptyData);
    setErrors({});
  }

  const handleChange = (value, type) => {
    setData({
      ...data,
      [type]: value
    })
  }

  const handleCheckboxChange = () => {
    setData({
      ...data,
      isActive: !data.isActive
    })
  }

  const handleTableCheckboxChange = (e, rowdata) => {
    e.preventDefault();
    setConfirmModalOpen(true);
    setConfirmModalData(rowdata)
  }

  const handleSubmit = () => {
    const validated = handleValidation();

    if (validated) {
      handleSave();
    }
  }

  const handleReset = (e) => {
    e.preventDefault();
    setData(emptyData);
  }

  const handleSave = async (existingData = {}) => {
    setLoading(true);
    let postObj = {

      pageTitle: data.pageTitle,
      pageName: data.pageName,
      pageDescription: data.pageDescription,
      isActive: data.isActive,
      pageUrl: data.pageUrl,
      pageId: data.pageId,
      categoryId: data.categoryId,

    }

    if (data.pageId) {
      postObj.pageId = data.pageId
    }

    if (existingData.pageId) {
      postObj = {
        pageTitle: existingData.pageTitle,
        pageName: existingData.pageName,
        pageDescription: existingData.pageDescription,
        isActive: existingData.isActive,
        pageUrl: existingData.pageUrl,
        pageId: existingData.pageId,
        categoryId: existingData.categoryId,

      }
    }

    const response = await postRequest(`Page/PostPage`, postObj);
    if (response.status === 200) {
      setEditing(false);
      setData(emptyData);
      getData();
    }
    setLoading(false);
  }



  const handleCloseCofirmationModal = () => {
    setConfirmModalData(emptyData);
    setConfirmModalOpen(false);
  }

  const handleConfirmModalYes = () => {
    confirmModalData.isActive = !confirmModalData.isActive;
    handleSave(confirmModalData);
    setConfirmModalOpen(false);
  }

  return (
    <MainA active="whats-new" className="v-whats-new-wrapper">
      <Loader loading={loading} />
      <Modal isOpen={confirmModalOpen} closeModal={handleCloseCofirmationModal} >
        <div>
          <h4>Are you sure you want to change the status?</h4>
          <div className='v-modal-button-wrapper'>
            <Button onClick={handleConfirmModalYes} type="primary" >Yes</Button>
            <Button onClick={handleCloseCofirmationModal} >No</Button>
          </div>
        </div>
      </Modal>
      {userType === 'admin' ?
        <div className='admin-wrapper'>
          <div className='heading-wrapper'>
            <h1>Pages</h1>
            {editing ? (
              <Button onClick={handleBack} className="add-btn" type="primary">Back</Button>
            ) : (
              <Button onClick={handleAdd} className="add-btn" type="primary">Add</Button>
            )}

          </div>

          {editing ? (
            <div className='edit-wrapper'>
              <div className='row'>
                <label>Page Name</label>
                <Input type="text" value={data.pageName} onChange={(e) => handleChange(e.target.value, 'pageName')} placeholder="Enter Page Name here..." />
                {errors.pageName && (
                  <ErrorMsg className="v-errorMsg">{errors.pageName}</ErrorMsg>
                )}
              </div>
              <div className='row'>
                <label>Page Title</label>
                <Input type="text" value={data.pageTitle} onChange={(e) => handleChange(e.target.value, 'pageTitle')} placeholder="Enter Page Title here..." />
                {errors.pageTitle && (
                  <ErrorMsg className="v-errorMsg">{errors.pageTitle}</ErrorMsg>
                )}
              </div>
              <div className='row'>
                <label>Page Description</label>

                <CustomEditor data={data.pageDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChange(data, 'pageDescription')
                  }}
                />
                {errors.pageDescription && (
                  <ErrorMsg className="v-errorMsg">{errors.pageDescription}</ErrorMsg>
                )}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Category Name</label>
                <br />
                <select className="v-input" value={data.categoryId} onChange={(e) => handleChange(e.target.value, 'categoryId')}>
                  <option value="">Select</option>
                  {categoryData.map((wnd) => (
                    <option key={wnd.pageId} value={wnd.categoryId}>{wnd.categoryName}</option>
                  ))}
                </select>
                {errors.categoryId && (
                  <ErrorMsg className="v-errorMsg">{errors.categoryId}</ErrorMsg>
                )}
              </div>
            
              <div className='row'>
                <label>Redirect URL</label>
                <Input type="text" value={data.pageUrl} onChange={(e) => handleChange(e.target.value, 'pageUrl')} placeholder="Enter Redirect URL here..." />
                {errors.pageUrl && (
                  <ErrorMsg className="v-errorMsg">{errors.pageUrl}</ErrorMsg>
                )}

              </div>
            
              <div className='row checkbox-wrapper'>
                <label>Active</label>
                <Input type="checkbox" checked={data.isActive} onChange={handleCheckboxChange} />
              </div>
              <div>
                <Button onClick={handleReset} className="save-btn" type='primary'>Reset</Button>

                <Button onClick={handleSubmit} className="save-btn" type='primary'>Save</Button>
                <Button onClick={handleBack} className="save-btn" type=''>Cancel</Button>
              </div>
            </div>
          ) : (
            <div className='table-wrapper'>
              <Table pagination={true} pageSize={10}>
                <thead>
                  <tr>
                    <th>Pages Name</th>
                    <th>Category</th>
                    <th>Pages Title</th>
                    <th>Redirect URL</th>
                    <th>Active/InActive</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {pagesData?.map((wnd) => (
                    <tr key={wnd.pageId}>
                      <td>{wnd.pageName}</td>
                      <td>{wnd.category.categoryName}</td>
                      <td>{wnd.pageTitle}</td>

                      <td>
                      <Anchor to={"/blogs"}>{wnd.pageUrl}</Anchor>

                       
                      </td>

                      <td><Input type="checkbox" checked={wnd.isActive} onChange={(e) => handleTableCheckboxChange(e, wnd)} /></td>
                      <td> 
                        <Button onClick={() => handleEdit(wnd)} className="edit-btn" type="primary">Edit</Button> </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

        </div>
        : 'Page Not Found'

      }
    </MainA>
  );
}

export default WhatsNewPages;