import React, { Fragment, useState } from 'react';
import Header from '../../components/organs/Header Admin';
import "./styles.scss";



const Main = ({children, className, active }) => {

  const [sidebarStatus, setSidebarStatus] = useState('close');

  const getSidebarStatus = (status) => {
    setSidebarStatus(status)
  }

  return (
    <Fragment>
      <Header getSidebarStatus={getSidebarStatus} active={active} />
      <main className={`v-main v-sidebar-${sidebarStatus} ${className}`}>
        {children}
      </main>
    </Fragment>
    
  );
}

export default Main;