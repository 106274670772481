import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import { getCurrentUser, getDateTime } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/atoms/Table';

import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import Loader from '../../components/atoms/Loader';
import Modal from '../../components/atoms/Modal';
import Dropdown from '../../components/atoms/Dropdown';
import Offcanvas from '../../components/atoms/OffCanvas';
import CustomDropdown from '../../components/atoms/CustomDropdown';

import verifiedIcon from '../../assets/icons/verified.svg'

import './styles.scss';

const Queries = () => {
    const emptyData = {
        pageTitle: '',
        pageName: '',
        pageDescription: '',
        isActive: false,
        pageUrl: '',
        categoryId: ''
    };
    const [loading, setLoading] = useState(false);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [userType, setUserType] = useState('');
    const [queryData, setQueryData] = useState([]);
    const [filteredQueryData, setFilteredQueryData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [removeModalData, setRemoveModalData] = useState(emptyData);
    const [filterValue, setFilterValue] = useState({
        searchText: '',
        contactUsType: '',
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const handleCategoryTypeFilter = (value) => {
        const filterObj = {
            ...filterValue,
            contactUsType: value
        };
        setFilterValue(filterObj);
        handleFilterChange(filterObj);
    };

    const handleFilterTextChange = (value) => {
        const filterObj = {
            ...filterValue,
            searchText: value
        };
        setFilterValue(filterObj);
        handleFilterChange(filterObj);
    };

    const handleFilterChange = (filterObj) => {
        const searchText = filterObj.searchText.toLowerCase();
        const contactUsType = filterObj.contactUsType;

        if (searchText === '' && contactUsType === '') {
            setFilteredQueryData(queryData);
        } else {
            const filteredData = queryData.filter((pfd) => {
                const foundMatching =
                    pfd.contactUsTypeName?.toLowerCase().includes(searchText) ||
                    pfd.contactUsType?.contactUsTypeName?.toLowerCase().includes(searchText) ||
                    pfd.firstName?.toLowerCase().includes(searchText) ||
                    pfd.lastName?.toLowerCase().includes(searchText) ||
                    pfd.company?.toLowerCase().includes(searchText) ||
                    pfd.jobTitle?.toLowerCase().includes(searchText) ||
                    pfd.email?.toLowerCase().includes(searchText) ||
                    pfd.description?.toLowerCase().includes(searchText);

                if ((contactUsType === '' || pfd.contactUsType?.contactUsTypeName === contactUsType) && foundMatching) {
                    return pfd;
                }

                return false;
            });

            setFilteredQueryData(filteredData);
        }
    };

    useEffect(() => {
        const user = getCurrentUser();
        const userTypeId = 4;

        if (userTypeId === 4) {
            setUserType('admin');
        }

        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`Contact/GetContactUs`);
        setQueryData(resp.data);
        setFilteredQueryData(resp.data);
        setLoading(false);
    };

    const removeQuery = async (contactUsId) => {
        setLoading(true);
        await getRequest(`Contact/Delete/${contactUsId}`);
        getData();
        setLoading(false);
    };

    const handleViewDetail = (data) => {
        setSelectedRowData(data);
        setConfirmModalOpen(true);
    };

    const handleRemoveQuery = (data) => {
        setRemoveModalOpen(true);
        setRemoveModalData(data);
    };

    const handleCloseConfirmationModal = () => {
        setSelectedRowData(null);
        setConfirmModalOpen(false);
    };

    const toggleDropdown = (id) => {
        setOpenDropdownId(prevId => (prevId === id ? null : id));
    };

    const handleCloseRemoveModal = () => {
        setRemoveModalData(emptyData);
        setRemoveModalOpen(false);
    };

    const handleRemoveModalYes = async () => {
        await removeQuery(removeModalData.contactUsId);
        setRemoveModalOpen(false);
    };

    return (
        <MainA active="whats-new" className="b-querry-wrapper">
            <Loader loading={loading} />

            <Offcanvas isOpen={confirmModalOpen} closeOffcanvas={handleCloseConfirmationModal}>
                <div className='data'>
                    <h4>Query Details</h4>
                    {selectedRowData && (
                        <>
                            <div className="row">
                                <strong>Name: </strong>
                                <p>{selectedRowData.firstName} {selectedRowData.lastName}</p>
                            </div>
                            <div className="row">
                                <strong>Company: </strong>
                                <p>{selectedRowData.company}</p>
                            </div>
                            <div className="row">
                                <strong>Job Title: </strong>
                                <p>{selectedRowData.jobTitle}</p>
                            </div>
                            <div className="row">
                                <strong>Phone Number: </strong>
                                <p>{selectedRowData.phoneNumber}</p>
                            </div>
                            <div className="row">
                                <strong>Type Name: </strong>
                                <p>{selectedRowData.contactUsType?.contactUsTypeName}</p>
                            </div>
                            <div className="row">
                                <strong>Email: </strong>
                                <p>{selectedRowData.email}</p>
                            </div>
                            <div className="row">
                                <strong>Valid Email: </strong>
                                <p>{selectedRowData.isValidEmail ? 'Yes' : 'No'}</p>
                            </div>
                            <div className="row">
                                <strong>Newsletter: </strong>
                                <p>{selectedRowData.newsLetter ? 'Subscribed' : 'Not Subscribed'}</p>
                            </div>
                            <div className="row">
                                <strong>Notes: </strong>
                                <p>{selectedRowData.notes ? 'Yes' : 'NA'}</p>
                            </div>
                        </>

                    )}
                </div>
            </Offcanvas>

            <Modal isOpen={removeModalOpen} closeModal={handleCloseRemoveModal} className="custom-modal">
                <div className='circle'>!</div>
                <h4>Are you sure? </h4>
                <p> you want to remove the query </p>
                <div className="v-modal-button-wrapper">
                    <Button onClick={handleRemoveModalYes} type="primary" className="button-1">Yes, Remove it </Button>
                    <Button onClick={handleCloseRemoveModal} className="button-2" >Cancel</Button>
                </div>
            </Modal>

            {userType === 'admin' ? (
                <div className="query-wrapper">
                    <div className="filter-wrapper">
                        <input
                            className="search-input"
                            type="search"
                            placeholder="Search..."
                            value={filterValue.searchText}
                            onChange={(e) => handleFilterTextChange(e.target.value)}
                        />
                        <CustomDropdown
                            options={[
                                { value: '', label: 'All Queries' },
                                ...(queryData ? [...new Set(queryData.map((wnd) => wnd.contactUsType?.contactUsTypeName))]
                                    .filter((typeName) => typeName)
                                    .map((typeName) => ({ value: typeName, label: typeName })) : [])
                            ]}
                            value={{ value: filterValue.contactUsType, label: filterValue.contactUsType || 'All Queries' }}
                            placeholder="Select Query Type"
                            onSelect={(selectedOption) => handleCategoryTypeFilter(selectedOption?.value || '')}
                            className="query-type-dropdown"
                        />

                    </div>

                    <Table pagination={true} pageSize={10}>
                        <thead>
                            <tr>
                                <th>Personal Info</th>
                                <th>Company</th>
                                <th>Contact & Email</th>
                                <th>Type Name</th>
                                <th>Date & Time</th>
                                <th>Newsletter</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredQueryData.length > 0 && filteredQueryData.map((wnd) => (
                                <tr key={wnd.contactUsId}>
                                    <td>
                                        {wnd.firstName} {wnd.lastName}
                                        {wnd.jobTitle ? ` - ${wnd.jobTitle}` : ''}
                                    </td>

                                    <td>{wnd.company}</td>
                                    <td>
                                        <p>
                                            {wnd.email && (
                                                <>
                                                    {wnd.email}
                                                    {wnd.isValidEmail && (
                                                        <img src={verifiedIcon} alt="Verified" style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                                                    )}
                                                </>
                                            )}
                                        </p>
                                        <p>{wnd.phoneNumber}</p>
                                    </td>
                                    <td>{wnd.contactUsType?.contactUsTypeName}</td>
                                    <td>
                                        {new Date(wnd.contactDateTime).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        })}
                                        <br />
                                        {new Date(wnd.contactDateTime).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })}
                                    </td>

                                    <td>
                                        {wnd.newsLetter ? (
                                            <FontAwesomeIcon icon={faCheck} className="green-icon" />
                                        ) : (
                                            <FontAwesomeIcon icon={faTimes} className="red-icon" />
                                        )}
                                    </td>
                                    <td>
                                        <Dropdown
                                            isOpen={openDropdownId === wnd.contactUsId}
                                            toggleDropdown={() => toggleDropdown(wnd.contactUsId)}
                                        >
                                            <a onClick={() => handleViewDetail(wnd)}>View detail</a>
                                            <a onClick={() => handleRemoveQuery(wnd)}>Delete</a>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>


                </div>
            ) : (
                'Page Not Found'
            )}
        </MainA>
    );
};

export default Queries;
