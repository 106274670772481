import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { getRequest, postRequest } from "../../utils/axios";
import { NotificationManager } from 'react-notifications';
import { Tooltip } from 'react-tooltip';

import MainA from '../../layout/Main Admin';
import Loader from "../../components/atoms/Loader";
import Button from "../../components/atoms/Button";
import Modal from '../../components/atoms/Modal';
import Upload from "../../components/atoms/Upload";
import Table from '../../components/atoms/Table';
import ToggleSwitch from "../../components/atoms/ToggleSwitch";

import Input from '../../components/atoms/Input';
import plus from '../../assets/icons/plus.svg';
import edit from '../../assets/icons/edit.svg';
import table from '../../assets/icons/table.svg';
import grid from '../../assets/icons/grid.svg';

import './styles.scss';

const Client = () => {
    const [clients, setClients] = useState([]);
    const [userCounts, setUserCounts] = useState({});
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [imageType, setImageType] = useState('');
    const [viewType, setViewType] = useState("grid");

    const [clientData, setClientData] = useState({
        clientId: 0,
        clientName: '',
        leftUpdatedDateTime: new Date().toISOString(),
        isActive: false,
        hostName: '',
        clientHomeImage: '',
        clientLogo: '',
        clientShortName: '',
        clientCode: '',
        dynamicHostName: ''
    });


    const [imageFile, setImageFile] = useState(null);
    const [resetFile, setResetFile] = useState(0);

    const imageBaseUrl = "https://dynamicproductstorage.blob.core.windows.net/clientimages/";

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await getRequest('Client/GetClients');
            setClients(response.data);
        } catch (error) {
            console.error("Error fetching client data", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const fetchClientData = async () => {
        try {
            const response = await getRequest('Client/GetClients');
            setClients(response.data);

            const counts = {};
            await Promise.all(response.data.map(async (client) => {
                const userResponse = await getRequest(`user/GetClientUsers/${client.clientId}`);
                counts[client.clientId] = userResponse.data.length;
            }));
            setUserCounts(counts);
        } catch (error) {
            console.error("Error fetching client data", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientData();
    }, []);

    const handleSubmit = async () => {
        try {
            await postRequest('Client/PostClient', {
                ...clientData,
                leftUpdatedDateTime: new Date().toISOString(),
            });

            fetchClients();
            setShowModal(false);

            setClientData({
                clientId: 0,
                clientName: '',
                hostName: '',
                dynamicHostName: '',
                clientShortName: '',
                clientHomeImage: '',
                clientLogo: '',
                clientCode: '',
                isActive: false,
                leftUpdatedDateTime: new Date().toISOString()
            });

            NotificationManager.success('Client added/updated successfully!');
        } catch (error) {
            console.error("Error submitting client", error);
            NotificationManager.error('Failed to submit client data');
        }
    };

    const handleOpenUploadModal = (clientId, type) => {
        setSelectedClientId(clientId);
        setImageType(type);
        setShowUploadModal(true);
    };

    const handleCloseUploadModal = () => {
        setShowUploadModal(false);
        setSelectedClientId(null);
        setImageType('');
        setImageFile(null);
        setResetFile(resetFile + 1);
    };

    const handleUpload = (file) => {
        setImageFile(file);
    };

    const handleUploadSubmit = async (e) => {
        e.preventDefault();

        if (!imageFile || !selectedClientId) {
            NotificationManager.error("Please select a file and client");
            return;
        }

        const formData = new FormData();
        formData.append('imageName', imageFile);
        formData.append('clientId', selectedClientId);

        try {
            const response = await postRequest('Client/PostLogo', formData);

            if (response.status === 200) {
                fetchClients();
                handleCloseUploadModal();
                NotificationManager.success('Image uploaded successfully!');
            } else {
                NotificationManager.error('Failed to upload image');
            }
        } catch (error) {
            NotificationManager.error('Network error occurred during upload');
        }
    };

    return (
        <MainA className="b-client-wrapper">
            <Loader loading={loading} />
            <div className="wrapper">
                <div className="head">
                    <h4>Clients</h4>
                    <div className="view-toggle">
                        <div className="clickables">
                            <img
                                src={grid}
                                alt="Grid View"
                                onClick={() => setViewType("grid")}
                                className={viewType === "grid" ? "active-view" : ""}
                            />
                            <img
                                src={table}
                                alt="Table View"
                                onClick={() => setViewType("table")}
                                className={viewType === "table" ? "active-view" : ""}
                            />
                        </div>
                        <Button onClick={() => setShowModal(true)}>
                            <img src={plus} alt="add icon" /> Add Client
                        </Button>
                    </div>
                </div>
                {!loading && (
                    viewType === "grid" ? (
                        <div className="client-list">
                            {Array.isArray(clients) && clients.length > 0 ? (
                                clients.map((client, index) => (
                                    <div className="client-row" key={client.clientId ? client.clientId : `client-${index}`}>
                                        <div className="logos">
                                            <div className="client-image" onClick={() => handleOpenUploadModal(client.clientId, 'home')}>
                                                {client.clientHomeImage ? (
                                                    <img
                                                        src={`${imageBaseUrl}${client.clientHomeImage}`}
                                                        alt={`${client.clientName} Home`}
                                                        data-tooltip-id={`home-image-${client.clientId}`}
                                                        style={{ width: '50px', cursor: 'pointer' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={edit}
                                                        alt="Upload home image"
                                                        data-tooltip-id={`home-image-${client.clientId}`}
                                                        style={{ width: '20px', cursor: 'pointer' }}
                                                    />
                                                )}
                                                <Tooltip id={`home-image-${client.clientId}`} place="top">
                                                    Home Image
                                                </Tooltip>
                                            </div>
                                            <div className="client-image">
                                                {client.clientLogo ? (
                                                    <img
                                                        src={`${imageBaseUrl}${client.clientLogo}`}
                                                        alt={`${client.clientName} Logo`}
                                                        data-tooltip-id={`logo-image-${client.clientId}`}
                                                        style={{ width: '50px' }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={edit}
                                                        alt="Upload logo"
                                                        data-tooltip-id={`logo-image-${client.clientId}`}
                                                        style={{ width: '20px', cursor: 'pointer' }}
                                                        onClick={() => handleOpenUploadModal(client.clientId, 'logo')}
                                                    />
                                                )}
                                                <Tooltip id={`logo-image-${client.clientId}`} place="top">
                                                    Logo Image
                                                </Tooltip>
                                            </div>

                                        </div>
                                        <div className="details">
                                            <div className="client-item">
                                                <strong>Client Name: </strong>
                                                <Link to={`/client-data/${client.clientId}`}>
                                                    {client.clientName}
                                                </Link>
                                            </div>
                                            <div className="client-item">
                                                <strong>Host Name: </strong>
                                                <p>
                                                    {client.hostName}
                                                </p>
                                            </div>
                                            <div className="client-item">
                                                <strong>Client Code: </strong>
                                                <p>
                                                    {client.clientCode ? client.clientCode : '-'}
                                                </p>
                                            </div>
                                            <div className="client-item">
                                                <strong>Website Link: </strong>
                                                <p>
                                                    {client.dynamicHostName ? client.dynamicHostName : '-'}
                                                </p>
                                            </div>
                                            <div className="client-item">
                                                <strong>Short Name: </strong>
                                                <p>
                                                    {client.clientShortName}
                                                </p>
                                            </div>
                                            <div className="client-item">
                                                <strong>User Count: </strong>
                                                <a
                                                    href={`/users/${client.clientId}`}
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                >
                                                    {userCounts[client.clientId] || 0}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-clients">No clients available</div>
                            )}
                        </div>
                    ) : (
                        <div className="client-table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Host Name</th>
                                        <th>Client Code</th>
                                        <th>Website Link</th>
                                        <th>Short Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients.map((client) => (
                                        <tr key={client.clientId}>
                                            <td>
                                                <div className="client-info">
                                                    <img
                                                        src={`${imageBaseUrl}${client.clientHomeImage}`}
                                                        alt={`${client.clientName} Home`}
                                                        data-tooltip-id={`home-image-${client.clientId}`}
                                                        style={{ width: '50px', marginRight: '10px' }}
                                                    />
                                                    <Link to={`/client-data/${client.clientId}`}>
                                                        {client.clientName}
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>{client.hostName}</td>
                                            <td>{client.clientCode || '-'}</td>
                                            <td>{client.dynamicHostName || '-'}</td>
                                            <td>{client.clientShortName || '-'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )
                )}
            </div>

            <Modal isOpen={showModal} closeModal={() => setShowModal(false)} className="client-upload">
                <h2>Add Client</h2>
                <div className="data">
                    <div className="row">
                        <div className="add">
                            <label>Client Name</label>
                            <Input
                                name="clientName"
                                value={clientData.clientName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="add">
                            <label>Host Name</label>
                            <Input
                                name="hostName"
                                value={clientData.hostName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="add">
                            <label>Client Code</label>
                            <Input
                                name="clientCode"
                                value={clientData.clientCode}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="add">
                            <label>Website Link</label>
                            <Input
                                name="dynamicHostName"
                                value={clientData.dynamicHostName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="add">
                            <label>Short Name</label>
                            <Input
                                name="clientShortName"
                                value={clientData.clientShortName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="add">
                            <label>Is Active</label>
                            <ToggleSwitch
                                checked={clientData.isActive}
                                onChange={(e) => setClientData({ ...clientData, isActive: e.target.checked })}
                            />
                        </div>
                    </div>
                </div>
                <Button onClick={handleSubmit}>Submit</Button>
            </Modal>

            <Modal isOpen={showUploadModal} closeModal={handleCloseUploadModal} className="upload-image">
                <h2>Upload {imageType === 'home' ? 'Home Image' : 'Logo'} for Client</h2>
                <form onSubmit={handleUploadSubmit}>
                    <Upload
                        className="upload-input"
                        allowedFiles=".png,.jpg,.jpeg,.svg"
                        onChange={handleUpload}
                        resetFile={resetFile}
                    />
                    <Button className="gallery-btn" type="submit">Submit</Button>
                </form>
            </Modal>
        </MainA>
    );
};

export default Client;
