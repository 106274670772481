import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { postRequest, getRequest } from '../../utils/axios';

import MainA from '../../layout/Main Admin';
import Button from '../../components/atoms/Button';
import { Tooltip } from 'react-tooltip'
import Loader from '../../components/atoms/Loader';

import backArrow from '../../assets/icons/arrow-left.svg';

import './styles.scss';

const EventVideo = () => {
    const [pageName, setPageName] = useState('');
    const [videos, setvideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [loading, setLoading] = useState(false);

    const { page } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const storedPageName = sessionStorage.getItem('pageName');
        if (storedPageName) {
            setPageName(storedPageName);
            sessionStorage.removeItem('pageName');
        }
        fetchVideos();
        fetchSelectedVideos(page);
    }, [page]);

    const fetchVideos = async () => {
        setLoading(true);
        try {
            const resp = await getRequest('Video/GetVideo');
            setvideos(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Failed to fetch videos');
        }
    };

    const fetchSelectedVideos = async (pageId) => {
        setLoading(true);
        try {
            const resp = await getRequest(`PageVideo/GetPageVideo/${pageId}`);
            if (resp.data && resp.data[0]?.pageName) {
                setPageName(resp.data[0].pageName);
            } else {
                NotificationManager.error('Page name not found in the response');
            }
    
            setSelectedVideos(resp.data || []);
        } catch (error) {
            NotificationManager.error('Failed to fetch selected videos: ' + error.message);
        } finally {
            setLoading(false);
        }
    };
    

    const handleVideoSelection = (video) => {
        if (isSelected(video)) {
            setSelectedVideos(selectedVideos.filter(v => v.videoId !== video.videoId));
        } else {
            setSelectedVideos([...selectedVideos, video]);
        }
    };

    const isSelected = (video) => {
        return selectedVideos.some(v => v.videoId === video.videoId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const payload = selectedVideos.map(video => ({
                clientId: 0,
                pageId: page,
                videoId: video.videoId,
            }));
            await postRequest('PageVideo/PostPageVideo', payload);
            setLoading(false);
            NotificationManager.success('Videos saved successfully');
        } catch (error) {
            setLoading(false);
            NotificationManager.error('Failed to save videos');
        }
    };

    return (
        <MainA className="b-event-video">
            <Loader loading={loading} />
            <div className="heading-wrapper">
                <h1>{pageName || 'Event Videos'}</h1>
                <Button onClick={() => navigate(-2)}> <img src={backArrow} alt='back arrow' /> Back</Button>
            </div>
            <div className="video-container">
                <div className='available-videos-container'>
                    <h2>Available Videos ({videos.filter(video => video.isActive).length})</h2>
                    <div className="available-videos">
                        {videos.filter(video => video.isActive && !isSelected(video)).length > 0 ? (
                            videos.filter(video => video.isActive && !isSelected(video)).map((wnd) => (
                                <div
                                    key={wnd.videoId}
                                    className={`video ${isSelected(wnd) ? 'selected' : ''}`}
                                    onClick={() => handleVideoSelection(wnd)}
                                >
                                    <iframe
                                        src={wnd.videoFilePath.replace("watch?v=", "embed/")}
                                        className="thumbnail-image"
                                        title={wnd.videoFileTitle}
                                        allowFullScreen
                                    />
                                    <div className="video-name">
                                        <span className="truncate-text" id={`video-${wnd.videoId}`}>
                                            {wnd.videoFileTitle}
                                        </span>
                                        <p>Select</p>
                                    </div>
                                    <Tooltip anchorId={`video-${wnd.videoId}`} content={wnd.videoFileTitle} />
                                </div>
                            ))
                        ) : (
                            <div className='please-select-text'>
                                No active videos available
                            </div>
                        )}
                    </div>

                </div>
                <div className='selected-videos-container'>
                    <div className="head">
                        <h2>Selected Videos ({selectedVideos.length})</h2>
                        <Button onClick={handleSubmit}>Save</Button>
                    </div>

                    <div className="video-add">
                        <div className="selected-video-list">
                            {selectedVideos.length > 0 ? selectedVideos.map((video) => (
                                <div key={video.videoId} className="selected-videos">
                                    <iframe
                                        src={video.videoFilePath.replace("watch?v=", "embed/")}
                                        title={video.videoFileTitle}
                                        allowFullScreen
                                    />
                                    <div className="deselect-video">
                                        <span className="truncate-text">
                                            {video.videoFileTitle}
                                        </span>
                                        <p onClick={() => handleVideoSelection(video)}>Deselect</p>
                                    </div>
                                </div>
                            )) : (
                                <div className='please-select-text'>
                                    No Video Selected
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </MainA>
    );
};

export default EventVideo;
