import axios from "axios"; 
const API_URL = "https://dynamicapi.memeticsolutions.in/api"; 
const META_TAGS_AUTO_GPT_URL = "https://prod2-12.centralindia.logic.azure.com:443/workflows/2c2991af49cd4307b7f39584a8821635/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Fsyf2SPKfEtMZWs7Vqab7oDbkL7JVgkuZm9hfpxiBhM"; 

const getToken = () => {
    let token = localStorage.getItem('token');
    if (token == null) return ''; 
    return JSON.parse(token).token;
}
const getDynamicHostName = () => {
    let token = localStorage.getItem('token');
    if (token == null) return ''; 
    return JSON.parse(token).dynamicHostName;
}

const getRequest = async (url, headers) => {
    const myheaders = { 
        headers: {
            "Authorization" : `Bearer ${getToken()}`          
        } 
        
    }
    try {
        const resp = await axios.get(`${API_URL}/${url}`, {...myheaders, ...headers});
        return resp
    } catch (error) {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.href =  `${window.location.origin}`;
        }

        return error
    }
    
}


const postRequest = async (url, params, headers) => {
    const myheaders = { 
        headers: {
            "Authorization" : `Bearer ${getToken()}`
        } 
    }
    try {
        const resp = await axios.post(`${API_URL}/${url}`, params, {...myheaders, ...headers});
        return resp
    } catch (err) {
        if (err.response.status === 401) {
            localStorage.clear();
            window.location.href =  `${window.location.origin}`;
        }

        return err
    }
    
}

const postAutoRequest = async (params) => {
   
    try {
        const resp = await axios.post(`${META_TAGS_AUTO_GPT_URL}`, params);
        return resp
    } catch (err) {
        if (err.response.status === 401) {
            localStorage.clear();
            window.location.href =  `${window.location.origin}`;
        }

        return err
    }
    
}

export { getRequest, postRequest, postAutoRequest, getToken, getDynamicHostName }