import React, { useEffect, useState, useMemo } from 'react';
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';

import { getCurrentUser, getCurrentUserImage, getClientHomeImage } from "../../../utils/utils";

import Image from "../../atoms/Image";
import Button from "../../atoms/Button";
import SideNavigation from '../../organs/SideNavigation';

import userBlack from '../../../assets/icons/users-black.svg';
import password from '../../../assets/icons/password.svg';
import logout from '../../../assets/icons/log-out.svg';

import './styles.scss';

const Header = ({ className, getSidebarStatus, academicYearUpdated = null, active, ...rest }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const currentUser = getCurrentUser();
    const currentUserImage = useMemo(() => getCurrentUserImage(), []);
    const clientHomeImage = useMemo(() => getClientHomeImage(), []);
    const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/clientimages/";

    const username = useMemo(() => {
        return currentUser ? `${currentUser.userFirstName} ${currentUser.userLastName || ''}` : '';
    }, [currentUser]);

    useEffect(() => {
        const isDesktop = window.innerWidth > 1024;
        if (isDesktop) {
            setSidebarOpen(true);
            getSidebarStatus('open');
        } else {
            getSidebarStatus('close');
        }
    }, [getSidebarStatus]);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
        getSidebarStatus('close');
    };

    const toggleDropdown = () => setDropdownOpen((prev) => !prev);

    return (
        <header className={`v-header ${className}`} {...rest}>
            <SideNavigation sidebaropen={sidebarOpen} closeSidebar={closeSidebar} username={username} active={active} />

            <div className="clickable-data">
                <a onClick={() => navigate('/reset-password')} data-tooltip-id="password-tooltip" data-tooltip-content="Change Password">
                    <img src={password} alt="Change Password" className="icon-image" />
                </a>
                <Tooltip id="password-tooltip" place="bottom" />

                <a onClick={() => navigate('/users')} data-tooltip-id="users-tooltip" data-tooltip-content="Users">
                    <img src={userBlack} alt="Users" className="icon-image" />
                </a>
                <Tooltip id="users-tooltip" place="bottom" />

                <div className="v-profileWrapper" onClick={toggleDropdown}>
                    <div className={`v-dropdown ${dropdownOpen ? '' : 'hide'}`}>
                        <div className="detailWrapper">
                            <Image className="user-image-dropdown" src={`${imageUrl}${clientHomeImage}`} />
                            <div className="name">{username}</div>
                        </div>
                        <div className="optionWrapper">
                            <Button type="link" onClick={handleLogout}>
                                Logout <img src={logout} alt="Logout" className="icon-image" />
                            </Button>
                        </div>
                    </div>

                    <div className="v-nameDiv">
                        <Image className="user-image-header" src={`${imageUrl}${clientHomeImage}`} />
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    getSidebarStatus: PropTypes.func.isRequired,
    academicYearUpdated: PropTypes.any,
    active: PropTypes.string
};

Header.defaultProps = {
    className: '',
    academicYearUpdated: null,
    active: ''
};

export default Header;
