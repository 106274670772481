
import { Droppable } from 'react-beautiful-dnd';
import Task from './task';
import './styles.scss';

const Column = ({column, tasks}) => {
    return (
        <div className={`${column.id} column ` } >
            <div className='title'>
                {column.title}
            </div>
            <Droppable droppableId={`${column.id}`} >
                {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {tasks.map((task, index) => (
                            <Task key={task.id} task={task} index={index} />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            
        </div>
        
    )
}

export default Column;