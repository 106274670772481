import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../utils/axios';
import { NotificationManager } from 'react-notifications';

import MainA from '../../layout/Main Admin';
import Loader from '../../components/atoms/Loader';
import Input from '../../components/atoms/Input';
import Table from '../../components/atoms/Table';
import Button from '../../components/atoms/Button';
import ErrorMsg from '../../components/atoms/ErrorMsg';
import Modal from '../../components/atoms/Modal';
import ToggleSwitch from '../../components/atoms/ToggleSwitch';
import CustomDropdown from '../../components/atoms/CustomDropdown';

import plus from '../../assets/icons/plus.svg';
import edit from '../../assets/icons/edit.svg';

import './styles.scss';

const Category = () => {
    const emptyData = {
        categoryName: '',
        categoryTypeId: '',
        isActive: false,
    };

    const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryNameData, setCategoryNameData] = useState([]);
    const [filteredCategoryNameData, setFilteredCategoryNameData] = useState([]);
    const [data, setData] = useState(emptyData);
    const [errors, setErrors] = useState({
        categoryName: '',
        categoryTypeId: '',
        isActive: false,
    });

    const [filterValue, setFilterValue] = useState({
        searchText: '',
        categoryType: '',
        status: '',
    });
    const [selectedPage, setSelectedPage] = useState(1);

    useEffect(() => {
        getData();
        getCategory();
    }, []);

    const getData = async () => {
        setLoading(true);
        const resp = await getRequest(`CategoryType/GetCategoryTypes`);
        setCategoryData(resp.data);
        setLoading(false);
    };

    const getCategory = async () => {
        const resp = await getRequest(`Category/GetCategories`);
        const respData = resp?.data?.reverse();
        setCategoryNameData(respData);
        setFilteredCategoryNameData(respData);
        setLoading(false);
    };

    const handleFilterTextChange = (value) => {
        const filterObj = { ...filterValue, searchText: value };
        setFilterValue(filterObj);
        handleFilterChange(filterObj);
    };

    const handleCategoryTypeFilter = (value) => {
        const filterObj = { ...filterValue, categoryType: value };
        setFilterValue(filterObj);
        handleFilterChange(filterObj);
    };

    const handleStatusFilterChange = (value) => {
        const filterObj = { ...filterValue, status: value };
        setFilterValue(filterObj);
        handleFilterChange(filterObj);
    };

    const handleFilterChange = (filterObj) => {
        const searchText = filterObj.searchText.toLowerCase();
        const categoryType = filterObj.categoryType;
        const status = filterObj.status;

        setSelectedPage(1);

        if (searchText === '' && categoryType === '' && status === '') {
            setFilteredCategoryNameData(categoryNameData);
        } else {
            const filteredData = categoryNameData.filter((pfd) => {
                const foundMatching =
                    pfd.categoryName?.toLowerCase()?.indexOf(searchText) > -1 ||
                    pfd.categoryType.categoryTypeName?.toLowerCase()?.indexOf(searchText) > -1;

                const isMatchingCategoryType = categoryType === '' || pfd.categoryType.categoryTypeName === categoryType;
                const isMatchingStatus = status === '' || (status === 'active' && pfd.isActive) || (status === 'inactive' && !pfd.isActive);

                return isMatchingCategoryType && foundMatching && isMatchingStatus;
            });

            setFilteredCategoryNameData(filteredData);
        }
    };

    const handleChange = (value, type) => {
        setData({ ...data, [type]: value || '' });
    };

    const handleCheckboxChange = () => {
        setData({ ...data, isActive: !data.isActive });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validated = handleValidation();
        if (validated) {
            handleSave();
        }
    };

    const handleSave = async () => {
        setLoading(false);
        let postObj = {
            categoryName: data.categoryName,
            categoryTypeId: data.categoryTypeId.value,
            isActive: data.isActive,
        };

        if (data.categoryId) {
            postObj.categoryId = data.categoryId;
        }

        const response = await postRequest(`Category/PostCategory`, postObj);
        if (response.status === 200) {
            setLoading(false);
            NotificationManager.success('Success message', 'Category Added Successfully');
            setData(emptyData);
            getData();
            getCategory();
            setAddCategoryModalOpen(false);
        }
    };

    const handleEdit = (editData) => {
        setData({
            ...data,
            categoryId: editData.categoryId,
            categoryName: editData.categoryName,
            categoryTypeId: {
                label: editData.categoryType?.categoryTypeName,
                value: editData.categoryType?.categoryTypeId,
            },
            isActive: editData.isActive,
        });
        setAddCategoryModalOpen(true);
    };

    const handleValidation = () => {
        const errorObj = {};

        if (data.categoryName === '') {
            errorObj.categoryName = 'Please enter Category Name';
        } else {
            errorObj.categoryName = '';
        }
        if (data.categoryTypeId === 'Select') {
            errorObj.categoryTypeId = 'Please select Category Type';
        } else {
            errorObj.categoryTypeId = '';
        }

        setErrors({ ...errors, ...errorObj });

        const validated = data.categoryName && data.categoryTypeId;
        return validated;
    };

    const handleAddCategoryModalOpen = () => {
        setAddCategoryModalOpen(true);
    };

    const handleAddCategoryModalClose = () => {
        setAddCategoryModalOpen(false);
        setData(emptyData);
    };

    return (
        <MainA active="expense-head-admin" className="b-category-wrapper">
            <Loader loading={loading} />

            <Modal isOpen={addCategoryModalOpen} closeModal={handleAddCategoryModalClose}>
                <div className="form-modal-content">
                    <h2>Add Category</h2>
                    <form>
                        <div className="form-group">
                            <label>Category Name</label>
                            <Input className="v-input" value={data.categoryName} onChange={(e) => handleChange(e.target.value, 'categoryName')} type="text" placeholder="Enter Category here..." />
                            {errors.categoryName && <ErrorMsg className="v-errorMsg">{errors.categoryName}</ErrorMsg>}
                        </div>
                        <div className="form-group">
                            <label>Category Type</label>
                            <CustomDropdown
                                className="custom-dropdown-variant"
                                value={data.categoryTypeId}
                                onSelect={(value) => handleChange(value, 'categoryTypeId')}
                                options={categoryData.map((wnd) => ({
                                    label: wnd.categoryTypeName,
                                    value: wnd.categoryTypeId,
                                    disabled: !wnd.isActive
                                }))}
                                placeholder="-Select-"
                            />
                            {errors.categoryTypeId && <ErrorMsg className="v-errorMsg">{errors.categoryTypeId}</ErrorMsg>}
                        </div>

                        <div className="form-group">
                            <label>Active</label>
                            <ToggleSwitch
                                checked={data.isActive}
                                onChange={handleCheckboxChange}
                            />
                        </div>

                        <div className="modal-footer">
                            <Button onClick={handleSubmit}>
                                Submit
                            </Button>
                            <Button onClick={handleAddCategoryModalClose} type="primary" >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>

            <div className="filter-wrapper">
                <Button onClick={handleAddCategoryModalOpen} className="add-category-btn">
                    <img src={plus} /> Add
                </Button>
                <div className="filter-buttons">
                    <Input
                        className="search-input"
                        type="search"
                        placeholder="Search..."
                        value={filterValue.searchText}
                        onChange={(e) => handleFilterTextChange(e.target.value)}
                    />
                    <CustomDropdown
                        options={[
                            { label: 'All Categories', value: '' },
                            ...categoryData.map((wnd) => ({
                                label: wnd.categoryTypeName,
                                value: wnd.categoryTypeName,
                            }))
                        ]}
                        className="category-type-dropdown"
                        onSelect={(option) => handleCategoryTypeFilter(option.value)}
                        value={{ label: 'All Categories', value: '' }}
                    />

                    <CustomDropdown
                        options={[
                            { label: 'Status', value: '' },
                            { label: 'Active', value: 'active' },
                            { label: 'Inactive', value: 'inactive' },
                        ]}
                        className="status-dropdown"
                        onSelect={(option) => handleStatusFilterChange(option.value)}
                        value={{ label: 'Status', value: '' }}
                    />

                </div>
            </div>

            <div className="wrapper">
                <Table pagination={true} onPageChange={setSelectedPage} selectedPage={selectedPage} pageSize={10} className="category-table">
                    <thead>
                        <th>Category</th>
                        <th>Category Type</th>
                        <th>Status</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {filteredCategoryNameData?.length > 0 ? (
                            filteredCategoryNameData.map((wnd) => (
                                <tr key={wnd.categoryId}>
                                    <td>{wnd.categoryName}</td>
                                    <td>{wnd.categoryType?.categoryTypeName}</td>
                                    <td>
                                        <p className={wnd.isActive ? 'active-status' : 'inactive-status'}> {wnd.isActive ? 'Active' : 'Inactive'} </p>
                                    </td>
                                    <td className="action-buttons">
                                        <a onClick={() => handleEdit(wnd)}>
                                            <img src={edit} />
                                        </a>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </MainA>
    );
};

export default Category;
